import { useState, useEffect } from "react";
import { GoldRateDTO } from "../models/goldRate";
import { RateRepository } from "../repositories/goldRateRepository";
import { useDispatch } from "react-redux";
import { GoldRateStateData, setGoldRate } from "../store/goldRateSlice";

export const useGoldRateHook = () => {
    const dispatch = useDispatch();

    useEffect(()=> {
        RateRepository.todaysRate().then((data)=> {
            dispatch(setGoldRate(data.toJson()));
        });
    }, [])
}
import { StyleSheet } from "aphrodite";


export const mobileView = '@media only screen and (max-width: 830px)'
export const bigScreen = '@media only screen and (min-width: 830px)'

export default StyleSheet.create({
  rootContainer: {
    position: "relative",
    padding: "0 10%",
    [mobileView]: {
      padding: "0",
    }
  },
  flexRow: {
    display: "flex"
  },
  colGap10: {
    columnGap: "10px"
  },
  rowGap10: {
    rowGap: "10px"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyFlexEnd: {
    justifyContent: "flex-end"
  },
  justifySpaceBetween: {
    justifyContent: "space-between"
  },
  justifySpaceAround:{
    justifyContent: "space-around"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  alignItemsFlexEnd: {
    alignItems: "flex-end",
  },
  width100P: {
    width: '100%'
  },
  widthAuto: {
    width: 'auto'
  },
  noLinkDecoration: {
    ':link': {
      textDecoration: 'none',
      color: '#000',
    },
    ':visited': {
      textDecoration: 'none',
      color: '#000',
    },
  },
  padding10: {
    padding: "10px"
  },
  margin10: {
    margin: "10px"
  },
  marginTB10: {
    margin: "10px 0"
  },
  marginLR15px: {
    margin: "0 15px 0 15px"
  },
  alignSelfFlexEnd: {
    alignSelf: "flex-end"
  },
  wspNoWrap: {
    whiteSpace: "nowrap"
  },
  grid: {
    display: "grid"
  },
  autoGridTemplate: {
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"
  },
  gridColSpanAll: {
    gridColumn: "1/span all"
  },
  postionRelative: {
    position: "relative"
  },
  textAlignCenter: {
    textAlign: "center"
  }
})




// css style generators
export const colsGap = (val: string) =>
  StyleSheet.create({
    _: {
      columnGap: `${val}`,
    },
  })._;
export const rowsGap = (val: string) =>
  StyleSheet.create({
    _: {
      rowGap: `${val}`,
    },
  })._;
export const padding = (val: string) =>
  StyleSheet.create({
    _: {
      padding: `${val}`,
    },
  })._;
export const margin = (val: string) =>
  StyleSheet.create({
    _: {
      margin: `${val}`,
    },
  })._;
export const width = (val: string) =>
  StyleSheet.create({
    _: {
      width: `${val}`,
    },
  })._;
export const minWidth = (val: string) =>
  StyleSheet.create({
    _: {
      minWidth: `${val}`,
    },
  })._;
export const maxWidth = (val: string) =>
  StyleSheet.create({
    _: {
      maxWidth: `${val}`,
    },
  })._;
export const height = (val: string) =>
  StyleSheet.create({
    _: {
      height: `${val}`,
    },
  })._;
export const backgroundColor = (val: string) =>
  StyleSheet.create({
    _: {
      background: val,
    },
  })._;
export const borderColor = (val: string) =>
  StyleSheet.create({
    _: {
      borderColor: val,
    },
  })._;
export const color = (val: string) =>
  StyleSheet.create({
    _: {
      color: val,
    },
  })._;
export const flex = (val: string) =>
  StyleSheet.create({
    _: {
      flex: `${val}`,
    },
  })._;
import { useLocation } from "react-router-dom"
import { StyleSheet, css } from "aphrodite"
import commonCss, { padding } from "../commonCss/commonCss"
import Billing from "../components/admin/billing.component"
import Rate from "../components/admin/rate.component"
import Customer from "../components/admin/customer.component"
import SideBar from "../components/admin/sidebarmenu.component"
import EditCustomer from "../components/admin/customersubcomponents/editcustomer.component"

import { darkAmberColor, primaryColor, white } from "../theme"
import { ButtonMedium } from "../components/button.component"
import AddProduct from "../components/admin/product.component"

function switchComp(pathname: string) {
    switch(pathname){

        case '/customer' :
            return <Customer/>
        
        case '/billing' :
            return <Billing />

        case '/rate' :
            return <Rate />
        
        case '/addProduct' :
            return <AddProduct />

        case '/editcustomer' :
            return <EditCustomer />

        default:
            return <Rate />

    }
}

export const AdminPage = () => {
    let location=useLocation();  
    
    return <div className={css(commonCss.flexRow, padding("50px 2rem"), commonCss.justifyCenter,)}>
            <SideBar />
            <div className={css(padding("0 2rem"), styles.adminContainer)}>
                {switchComp(location.pathname)}
            </div>
        </div>

}


const styles = StyleSheet.create({
    adminContainer: {
        flex: 'auto'
    },
    element:{
        padding: '10px',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 5px #aaa',
        marginBottom: '10px',
        textAlign:'center',
        border: `1px solid ${primaryColor}`

    },
    
})
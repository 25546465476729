import React, { useContext } from "react"
import { NavContext } from "./navbar.component"
import { StyleSheet, css } from "aphrodite"
import {primaryColor} from '../theme';
import { Link, useLocation } from "react-router-dom";
import globalCSS from "../commonCss/commonCss";
interface NavActionI extends React.HTMLAttributes<HTMLElement> {
    label: string
}

export default function NavAction({label}: NavActionI) {
    const routeFromLabel = label.split(" ").join("");
    const isActive = (useLocation().pathname.split("/")[1] || "BuyJewelleries") === routeFromLabel;
    const {handleNavAction} = useContext(NavContext);

    return <Link to={`/${label.split(" ").join("")}`} className={
        css(
            isActive
                ? styles.activeButton
                : styles.inActiveButton, 
            styles.actionButton,
            globalCSS.noLinkDecoration
        )} onClick={handleNavAction}>
        {label}
    </Link>
}

const styles = StyleSheet.create({
    actionButton: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px 0 10px',
        height: "90px",
        cursor: 'pointer',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
    },
    activeButton: {
        borderBottom: '4px solid '+ primaryColor
    },
    inActiveButton: {
        borderBottom: '4px solid transparent'
    }
});

export const primaryColor = "#FFCC01"
export const shadowColor = "#806E26"
export const backgroundColor = "#FEFEFE"
export const darkAmberColor = "#BF9850"
export const silverColor = "#A6A6A6"
export const white = "#FFF";
export const softBlack = "#000";
export const viewPortWidth = window.visualViewport?.width || 1080;
export const viewPortHeight = window.visualViewport?.width || 1920;
export const isMobileView = viewPortWidth < 800; 

import { StyleSheet, css } from "aphrodite"
import globalCSS from '../commonCss/commonCss'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreSelector } from "../store/store";
import { User } from "firebase/auth";
import { useContext, useEffect } from "react";
import { NavContext } from "./navbar.component";
import authRepository from "../repositories/authRepository";
import { login } from "../store/authSlice";
import { FaUserCircle } from "react-icons/fa";
import { setUser } from "../store/userSlice";
import { UserRepository } from "../repositories/userRepository";
import { UserDTO } from "../models/users";
const NavProfileView = () => {
    const authState: User = useSelector((state:StoreSelector) => state.auth);
    const dispatch = useDispatch();
    useEffect(()=> {
        authRepository.getCurrentUser().then(user => {
            user && dispatch(login(authRepository.parseUser(user as User)));
            UserRepository.getUserDetails(user?.uid as string).then(userDet => {
                dispatch(setUser(userDet?.toJson()))
            })
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const {handleNavAction} = useContext(NavContext);
    if (authState.uid) {
        return <Link className={css(globalCSS.noLinkDecoration, globalCSS.flexRow)} to="/profile" onClick={()=>handleNavAction()}>
            {authState.photoURL 
            ?   <img src={authState.photoURL} alt={authState.displayName + 'image'} className={css(styles.userProfileImg)} />
            :   <FaUserCircle className={css(styles.userProfileImg)}></FaUserCircle>
            }
            <div className={css(styles.padding10px)}> {authState.displayName} </div>
        </Link>
    } else {
        return <div className={css(globalCSS.noLinkDecoration)}>
            <Link className={css(globalCSS.noLinkDecoration, styles.padding10px)} to="/login" onClick={()=>handleNavAction()} >
                Login 
            </Link>
        </div>

    }
}

const styles = StyleSheet.create({
    userProfileImg: {
        height: '40px',
        width: '40px',
        borderRadius: "100%",
    },
    padding10px: {
        padding: '10px'
    }

})

export default NavProfileView
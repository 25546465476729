

export class UserDTO {
    private constructor(private _name: string, private _isAdmin: boolean, private _userId: string) {}

    get name(): string {
        if (!!this._name)
            return this._name
        return ""
    }

    get isAdmin(): boolean {
        if (!!this._isAdmin)
            return this._isAdmin
        return false
    }

    get id(): string {
        if (!!this._userId)
            return this._userId
        return ""
    }


    static fromJSON(userJson: any): UserDTO | undefined {
        if (!!userJson) {
            return new UserDTO(userJson.name, userJson.isAdmin, userJson.id)
        }
    }

    toJson() {
        return {
            name: this.name,
            isAdmin: this.isAdmin,
            id: this.id
        }
    }

}
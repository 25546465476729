import {css,StyleSheet} from 'aphrodite';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { CustomersRepository } from '../../repositories/customerRepository';
import SearchCustomers from './customersubcomponents/searchcustomer.component';
import commonCss, { colsGap, maxWidth, minWidth, padding, rowsGap, width } from '../../commonCss/commonCss';
import { primaryColor, softBlack } from '../../theme';
import { ButtonMedium } from '../button.component';





export default function Customer(){
    const customerDetails=useRef({name:'', contact:'', email:'', gender:'Female', age:'', locality:'', city:'Ghazipur', state:'Uttar Pradesh', country:'India', pincode:'233001', membershipId:''})

    function handleSubmit(){
        CustomersRepository.addCustomer({...customerDetails.current}).then((r)=> console.log(r.id,r.firestore,r.parent))
    }




    return <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>

                <div className={css(styles.form, padding('1.6rem'), minWidth('550px'), maxWidth('800px'))}>

                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Name</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.name=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Contact</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.contact=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>E-Mail</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.email=e.target.value}}
                            className={css(styles.input)}
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Gender</label>
                        <input
                            type="text"
                            defaultValue={'Female'}
                            onChange={(e) =>{customerDetails.current.gender=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Age</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.age=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Locality</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.locality=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>City</label>
                        <input
                            type="text"
                            defaultValue={'Ghazipur'}
                            onChange={(e) =>{customerDetails.current.city=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>State</label>
                        <input
                            type="text"
                            defaultValue={'Uttar Pradesh'}
                            onChange={(e) =>{customerDetails.current.state=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Country</label>
                        <input
                            type="text"
                            defaultValue={'India'}
                            onChange={(e) =>{customerDetails.current.country=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Pin Code</label>
                        <input
                            type="text"
                            defaultValue={'233001'}
                            onChange={(e) =>{customerDetails.current.pincode=e.target.value}}
                            className={css(styles.input)}
                            required
                        />
                    </div>
                    <div className={css(styles.item)}>
                        <label className={css(styles.label)}>Membership ID</label>
                        <input
                            type="text"
                            onChange={(e) =>{customerDetails.current.membershipId=e.target.value}}
                            className={css(styles.input)}
                        />
                    </div>

                    <div className={css(styles.buttonDiv)}>
                        <ButtonMedium onClick={() => handleSubmit()} color={primaryColor} className={css(styles.saveButton)} text={'Add Customer'}></ButtonMedium>
                    </div>

                </div>

                <div className={css(styles.form, padding('1.6rem'), minWidth('550px'), maxWidth('800px'))}>
                    <SearchCustomers />   
                </div>
    </div>

}




const styles=StyleSheet.create({

    form:{
        padding:'40px',
        boxShadow: '0px 1px 5px #aaa',  
        display:'grid',
        flex:1,   
    },

    item:{
        display:'flex',
        justifyContent:'space-between',
        margin:'7px', 
    },

    label:{
        padding:'10px',
        fontWeight:'bold',
        color:'#BF9850',

    },

    input:{
        padding:'10px',
        fontSize:'15px',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        transitionDuration:'0.2s',
        ':focus':{outline:'none',color:softBlack},

    },

    buttonDiv:{
        display:'flex',
        // boxShadow: '0px 0px 3px silver',
        marginTop:'20px',
        flexDirection:'row-reverse'
        
    },

    saveButton:{
        borderRadius: '20px',
        fontWeight: 'bold',
        width:'auto',
        marginRight:'auto',
        marginLeft:'auto'
    }



})
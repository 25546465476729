import { StyleSheet, css } from "aphrodite";

interface ImageAutoFitParams {
    src: string;
    alt: string;
    maxHeight?: string
}

interface ImageFixedHeightParams {
    src: string;
    alt: string;
    height: string
}

interface ImageFixedSizeParams {
    src: string;
    alt: string;
    height: string
    width: string
}


export const ImageAutoFit = ({src, alt, maxHeight}: ImageAutoFitParams) => {
    return <img src={src} alt={alt} className={css(styles.imgResponsive, maxHeight? mxHt(maxHeight): null )}/>
}

export const ImageFixedHeight = ({src, alt, height}: ImageFixedHeightParams) => {
    return <img src={src} alt={alt} className={css(styles.imgFixedHeight, height? ht(height): null )}/>
}

export const ImageFixedSize = ({src, alt, height, width}: ImageFixedSizeParams) => {
    return <img src={src} alt={alt} className={css(height? ht(height): null, width? wth(width): null )}/>
}

const styles = StyleSheet.create({
    imgResponsive: {
        width: "100%",
        height: "auto",
        objectFit: "cover"
    },

    imgFixedHeight: {
        width: "auto",
        objectFit: "cover"
    }
})

const mxHt = (height: string) => StyleSheet.create({
    mxHt: {
        maxHeight: height
    }
}).mxHt

const ht = (height: string) => StyleSheet.create({
    ht: {
        height: height
    }
}).ht

const wth = (width: string) => StyleSheet.create({
    wth: {
        width: width
    }
}).wth
import { createSlice } from "@reduxjs/toolkit";
import { User } from "firebase/auth";



const initialState: User = {
} as User;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login (state: User, action) {
            state = {
                ...action.payload
            } as User
            return state;
        },
        logout () {
            return initialState;
        }
    },
})

export const {login, logout} = authSlice.actions;
export default authSlice.reducer;
import { StyleSheet, css } from "aphrodite"
import logo from '../assets/img/sangini_black.png'
import NavAction from "./navaction.component"
import NavProfileView from "./navprofileview.component"

import { createContext } from "react"
import { Link } from "react-router-dom"
import { BreadCrumb } from "./breadCrumb.component"
import commonCss, { mobileView } from "../commonCss/commonCss"
import { backgroundColor, isMobileView } from "../theme"
import { useNavbarHook } from "../hooks/useNavbarHook"
import GoldRateDisplay from "./goldRateDisplay"
import globalCSS from '../commonCss/commonCss'

interface NavbarI extends React.HTMLAttributes<HTMLElement>{
    icon?: string,
    defaultAction: string
}
export const NavContext = createContext({} as any);


export default function Navbar({icon, defaultAction, children}: NavbarI) {
    const {isBreadCrumbOpened, handleNavAction, handleBreadCrumbToggle} = useNavbarHook();

    return <NavContext.Provider value={{handleNavAction}}>
        <div className = {css(styles.navMain)} onMouseLeave={()=>handleBreadCrumbToggle(false)}>
            {/* Hero content start contains Sangini Logo and BreadCrumb */}
            <div className={navBreadCrumbAndLogoContainer}>
                <Link to="/BuyJewelleries" className={css(commonCss.marginLR15px)} onClick={()=>handleNavAction()}>
                    <img src={icon || logo} alt="logo" className={css(styles.navLogoImg)} />
                </Link>
                {isMobileView && <BreadCrumb onClick={(val) => handleBreadCrumbToggle(val)}></BreadCrumb>}
            </div>
            {/* Hero content end */}

            {/* action items of nav starts */}
            {
                isMobileView && isBreadCrumbOpened
                ?   <div className={css(styles.actionItems)}>
                        {children}
                    </div>
                :   (isMobileView && !isBreadCrumbOpened)
                    ?   null
                    :   <div className={css(styles.actionItems)}>
                            {children}
                        </div>
            }
            {/* action items of nav ends */}

            <div className={css(globalCSS.flexRow, globalCSS.alignItemsCenter, globalCSS.justifyFlexEnd, globalCSS.width100P, styles.marginRight10P)}>
                <GoldRateDisplay/>
                <NavProfileView/>
            </div>

        </div>
    </NavContext.Provider>
};
Navbar.Actions = NavAction;

const styles = StyleSheet.create({
    marginRight10P: {
        marginRight: '10%',
        '@media only screen (max-width: 479px)': {
            marginRight: '10px'
        }
    },
    navMain: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '90px',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 10px #aaa',
        position: "sticky",
        background: backgroundColor,
        top: "0px",
        zIndex: 100,
        [mobileView]: {
        }

    },
    navLogoImg: {
        height: '70px',
        minWxidth: '61px',
        [mobileView]: {
            height: '55px'
            
        }
    },
    actionItems: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        // marginLeft: '50px',
        [mobileView]: {
            flexDirection: "column",
            position: "absolute",
            top: "88px",
            background: backgroundColor,
        }
        
    },
    navMarginLeft: {
        marginLeft: "8%",
        [mobileView]: {
            marginLeft: "10px",
        }
    },
});

const navBreadCrumbAndLogoContainer = css(
    commonCss.flexRow,
    styles.navMarginLeft,
    commonCss.justifyCenter,
    commonCss.alignItemsCenter,
)
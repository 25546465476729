type RateRef = React.MutableRefObject<{
    gold: {
        '18K': number;
        '22K': number;
        '24K': number;
        '24K995': number;
    };
    silver: number[];
}>

export const updateGoldRateRef = (e: any, rateRef: RateRef) => {
    rateRef.current.gold['24K995']=parseFloat(e.target.value);
    rateRef.current.gold['24K']=parseFloat((parseFloat(e.target.value)/(0.995)).toFixed(2));
    rateRef.current.gold['22K']=parseFloat((parseFloat(e.target.value)*(0.9167)/(0.995)).toFixed(2));
    rateRef.current.gold['18K']=parseFloat((parseFloat(e.target.value)*(0.75)/(0.995)).toFixed(2));
}


export const updateSilverRateRef = (e: any, rateRef: RateRef) => {
    rateRef.current.silver[0]=parseFloat((parseFloat(e.target.value)*(0.35)).toFixed(2));
    rateRef.current.silver[1]=parseFloat((parseFloat(e.target.value)*(0.5)).toFixed(2));
    rateRef.current.silver[2]=parseFloat(e.target.value)-6;
    rateRef.current.silver[3]=parseFloat(e.target.value);
                            
}
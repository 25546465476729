import { limit, orderBy, Timestamp } from "firebase/firestore";
import { Query } from "../library/query";
import { GoldRateDTO } from "../models/goldRate";




export class RateRepository {
    private static rateCollection = Query.getCollection('rates');

    static async todaysRate(): Promise<GoldRateDTO> {
        const rateData:any  = await this.rateCollection.findOne(orderBy('timestamp', 'desc'), limit(1));
        return GoldRateDTO.fromJSON(rateData);
    }

    static async updateRate(id: string, data:any) {
        await this.rateCollection.updateDocById(id,{
            ...data,
            timestamp: Timestamp.now().toMillis()
        });
    }

    static async AddRate(data:any) {
        await this.rateCollection.addNewDoc({
            ...data,
            timestamp: Timestamp.now().toMillis()
        });
    }
}
import { css,StyleSheet} from "aphrodite";
import { useRef } from "react";
import { CustomersDTO } from "../../../models/customers";
import { CustomersRepository } from "../../../repositories/customerRepository";
import { softBlack } from "../../../theme";
import commonCss, { colsGap, rowsGap, padding, minWidth, maxWidth } from "../../../commonCss/commonCss";



let initialDetails:CustomersDTO;

export function getID(customer:CustomersDTO){
    initialDetails = customer;
    // console.log(`hellotesting${id}`)
}

export default function EditCustomer(){

    const updatedDetails=useRef({   
                                name:  initialDetails.name || '', 
                                contact:  initialDetails.contact || '', 
                                email:  initialDetails.email || '', 
                                gender:  initialDetails.gender || '', 
                                age:  initialDetails.age || '', 
                                locality:  initialDetails.locality || '', 
                                city:  initialDetails.city || '', 
                                state:  initialDetails.state || '', 
                                country:  initialDetails.country || '', 
                                pincode:  initialDetails.pincode || '', 
                                membershipId:  initialDetails.membershipId || '',
                            })
    

    function handleSubmit(){
        CustomersRepository.editCustomer(initialDetails.docId , updatedDetails.current);
    }

    return<><div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
    <div className={css(styles.form, padding('1.6rem'), minWidth('400px'), maxWidth('800px'))}>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Name</label>
            <input
                type="text"
                defaultValue={initialDetails.name}
                onChange={(e) =>{updatedDetails.current.name=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Contact</label>
            <input
                type="text"
                defaultValue={initialDetails.contact}
                onChange={(e) =>{updatedDetails.current.contact=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>E-Mail</label>
            <input
                type="text"
                defaultValue={initialDetails.email || ''}
                onChange={(e) =>{updatedDetails.current.email=e.target.value}}
                className={css(styles.input)}
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Gender</label>
            <input
                type="text"
                defaultValue={initialDetails.gender}
                onChange={(e) =>{updatedDetails.current.gender=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Age</label>
            <input
                type="text"
                defaultValue={initialDetails.age}
                onChange={(e) =>{updatedDetails.current.age=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Locality</label>
            <input
                type="text"
                defaultValue={initialDetails.locality}
                onChange={(e) =>{updatedDetails.current.locality=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>City</label>
            <input
                type="text"
                defaultValue={initialDetails.city}
                onChange={(e) =>{updatedDetails.current.city=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>State</label>
            <input
                type="text"
                defaultValue={initialDetails.state}
                onChange={(e) =>{updatedDetails.current.state=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Country</label>
            <input
                type="text"
                defaultValue={initialDetails.country}
                onChange={(e) =>{updatedDetails.current.country=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Pin Code</label>
            <input
                type="text"
                defaultValue={initialDetails.pincode}
                onChange={(e) =>{updatedDetails.current.pincode=e.target.value}}
                className={css(styles.input)}
                required
            />
        </div>
        <div className={css(styles.item)}>
            <label className={css(styles.label)}>Membership ID</label>
            <input
                type="text"
                defaultValue={initialDetails.membershipId || ''}
                onChange={(e) =>{updatedDetails.current.membershipId=e.target.value}}
                className={css(styles.input)}
            />
        </div>
            <button onClick={handleSubmit} className={css(styles.submit)}>Update</button>
    </div>
    </div></>
}

const styles=StyleSheet.create({

    form:{
        padding:'40px',
        boxShadow: '0px 1px 5px #aaa',  
        display:'grid',
        flex:1,   
    },

    item:{
        display:'flex',
        justifyContent:'space-between',
        margin:'7px', 
    },

    label:{
        padding:'10px',
        fontWeight:'bold',
        color:'#BF9850',

    },

    input:{
        padding:'10px',
        fontSize:'15px',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        ':focus':{outline:'none',color:softBlack},

    },

    submit:{
        padding:'10px',
        fontSize:'14px',
        color:'#CBA135',
        backgroundColor:'white',
        border:'none',
        boxShadow: '0px 0px 3px silver',
        marginTop:'20px',
    },



})
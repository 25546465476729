import { useSelector } from 'react-redux';
import { StoreSelector } from '../../../store/store';
import { GoldRateDTO } from '../../../models/goldRate';
import {css,StyleSheet} from 'aphrodite';
import commonCss, { colsGap, margin, maxWidth, minWidth, padding, rowsGap, width } from '../../../commonCss/commonCss';
import { darkAmberColor, silverColor } from '../../../theme';


export default function DisplayRates(){
    const rate = useSelector((state: StoreSelector)=> state.goldRate); 
    const displayRate = GoldRateDTO.fromJSON(rate);

    return (
        <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
            
            <div className={css(styles.gold, padding('1.6rem'), minWidth('200px'), maxWidth('800px'))}>

                <div className={css(styles.title)}>Gold Rate</div>

                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>24K{' (99.5)'} </div>
                    <div>&#8377;{displayRate.rateOf24K995}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>24K </div>
                    <div>&#8377;{displayRate.rateOf24K}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>22K </div>
                    <div>&#8377;{displayRate.rateOf22K}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>18K </div>
                    <div>&#8377;{displayRate.rateOf18K}</div>
                </div>

            </div>
            <div className={css(styles.silver, padding('1.6rem'), minWidth('200px'),  maxWidth('800px'))}>

                <div className={css(styles.title)}>Silver Rate</div>
                
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>100% </div>
                    <div>&#8377;{displayRate.rateOfSilver100}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>90% </div>
                    <div>&#8377;{displayRate.rateOfSilver90}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>50% </div>
                    <div>&#8377;{displayRate.rateOfSilver50}</div>
                </div>
                <div className={css(styles.divli)}>
                    <div className={css(styles.type)}>35% </div>
                    <div>&#8377;{displayRate.rateOfSilver35}</div>
                </div>
            
            </div>
        </div>
    )
    
}

const styles= StyleSheet.create({
    gold:{
        color: darkAmberColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },
    silver:{
        color: silverColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',
    },
    divli:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    type:{
        fontWeight:'bold',
    },
    h:{
        textAlign:'center',
        fontSize:'1rem',
    },
    title:{
        padding:'5%',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },
})
import { css , StyleSheet} from "aphrodite";
import {CustomersRepository} from '../../../repositories/customerRepository';
import { useEffect, useRef, useState } from "react";
import { CustomersDTO } from "../../../models/customers";
import { ConfigProvider, Pagination } from 'antd';
import { getID } from "./editcustomer.component";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { IoMdSearch } from "react-icons/io";
import { darkAmberColor, silverColor, softBlack, white } from "../../../theme";
import { MdDeleteOutline } from "react-icons/md";
import commonCss, { backgroundColor, color, flex, width } from "../../../commonCss/commonCss";





const getDebounce = (callbackFunction: Function) => 
    setTimeout(() => {
        callbackFunction()
    }, 800);

let timer:any = null;


export default function SearchCustomers(){
    
    const searchValue = useRef('');
    const [customers, setCustomers] = useState([] as CustomersDTO[]);
    const [currentPage, setCurrentPage] = useState([] as CustomersDTO[]);
    const navigate = useNavigate();

    const getCustomers = () => {
        CustomersRepository.searchCustomer(searchValue.current).then((_customers) => {
            setCustomers(_customers.map(customer => CustomersDTO.fromJSON(customer)));
            if(customers.length<=10){
                setCurrentPage(customers);
            }else{
                setCurrentPage(customers.slice(0,10));
            }
        });
    }


    function showAllCustomers(){
        CustomersRepository.getAllCustomers().then(customers => {
            setCustomers(customers)
            if(customers.length<=10){
                setCurrentPage(customers)
            }else{
                setCurrentPage(customers.slice(0,9))
            }
        });

    }

        
    function handleChange() {
        if (timer) {
            clearTimeout(timer)
        }
        if(searchValue.current!=''){    
            timer = getDebounce(getCustomers)
        }else{
            timer = getDebounce(showAllCustomers)
        }
    }

    useEffect(()=> {showAllCustomers()}, []);

    return<div className={css(styles.container)}>

            <div className={css(styles.searchdiv)}>
                <IoMdSearch style={{width:'40px'}} color={darkAmberColor} size='1.5rem' />
                <input key={"Sa"} type="search" placeholder="Search By Name or Contact" onChange={(e)=>{searchValue.current=e.target.value;handleChange();}} className={css(styles.input)}/>
            </div>

            <div className={css(styles.resultdiv)}>
                {currentPage.map((customer, index) =>
                    <li key={index} className={css(styles.list,commonCss.flexRow,commonCss.margin10,color(softBlack))}>
                        <div style={{width:'30%'}}>{customer.name}</div>
                        <div>{customer.contact}</div> 
                        <div className={css(commonCss.flexRow,commonCss.alignItemsCenter,commonCss.justifySpaceBetween,width('13%'))}>
                            <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{getID(customer);navigate('/editcustomer');}}>
                                <FiEdit className={css(styles.button)} color= {darkAmberColor} size='1.1rem' />
                            </button>
                            <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{CustomersRepository.deleteCustomer(customer.docId).then(()=>handleChange())}}>
                                <MdDeleteOutline className={css(styles.button)} color= {darkAmberColor} size='1.3rem' />
                            </button>
                        </div>
                    </li>
                )}
            </div>

            <div className={css(styles.pagination)}>
                <ConfigProvider theme={{token:{
                                                colorPrimary: darkAmberColor,
                                                controlItemBgActive: white,
                                            }}} >
                <Pagination
                    style={{fontSize:'17px'}}
                    total={customers.length}
                    showQuickJumper
                    showTotal={(total) => `Total  ${total}  Customers`}
                    onChange={(page,pageSize)=>{
                        const startIndex = (page-1)*10
                        const endIndex = (page*10)-1;
                        if(page*10 <= customers.length){
                            setCurrentPage(customers.slice(startIndex,endIndex));
                        }else{
                            setCurrentPage(customers.slice(startIndex,));
                        }
                    }}/>
                </ConfigProvider>
            </div>
    </div>

}

const styles=StyleSheet.create({

    container:{
        display:'grid',
        gridTemplateRows:'1fr 12fr 1fr'
    },

    searchdiv:{
        paddingLeft:'5px',
        boxShadow: '0px 0px 3px #D9D9D9',
        display: 'flex',
        alignItems:'center',
    },

    input:{
        padding:'15px',
        fontSize:'15px',
        paddingLeft:'5px',
        color:softBlack,
        border:'none',
        width:'95%',
        ':focus':{outline:'none'},
    },

    resultdiv:{
        marginBottom:'20px',
        marginTop:'10px',
    },

    list:{
        display: "flex",
        padding:'0.5rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 0px 2px #D9D9D9',
    },

    button:{
        backgroundColor:'white',
        transitionDuration:'0.3s',
        padding:'0.2rem',
        ':hover':{color:softBlack},
    },

    pagination:{
        display:'flex',
        alignContent:'center',
        justifyContent:'space-around',
        padding:'7px',
    }

    
})    

import { ButtonMedium } from "../components/button.component";
import { Input } from "../components/input.component";
import {primaryColor, shadowColor} from "../theme"
import commonCSS, { mobileView } from "../commonCss/commonCss"
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as SignUpSVG } from "../signup.svg"
import logo from '../assets/img/sangini_black.png'
import { Link } from "react-router-dom";
import { useSignUpHook } from "../hooks/useSignUpHook";

export default function SignUpPage() {
    const {inputEmail, inputFirstName, inputLastName, inputPassword, handleSignups} = useSignUpHook();

    return <div className={css(commonCSS.flexRow, styles.signupDiv)}>
        <SignUpSVG className={css(styles.signupLeftSideView)}></SignUpSVG>
        <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter, styles.signupRightSideView)}>
            <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter)}>
                <img src={logo} alt="Sangini Jewellers" className={css(styles.signupHeaderLogo)} />
                <div className={css(styles.signupTextHeader)}>Sign up</div>
                <div className={css(styles.signupTextSubHeader)}>Create a new account</div>
                <div className={css(styles.nameDiv)}>
                    <Input widthVal="8.8rem" className={css(commonCSS.marginTB10)} type="text" placeholder="Enter First Name" autoFocus={true} onChangeValue={(val) => {inputFirstName.current = val}}/>
                    <Input widthVal="8.8rem" className={css(commonCSS.marginTB10)} type="text" placeholder="Enter Last Name" autoFocus={true} onChangeValue={(val) => {inputLastName.current = val}}/>
                </div>
                <Input className={css(commonCSS.marginTB10)} type="text" placeholder="Enter email" autoFocus={true} onChangeValue={(val) => {inputEmail.current = val}}/>
                <Input className={css(commonCSS.marginTB10)} type="password" placeholder="Enter password" onChangeValue={(val) => {inputPassword.current = val}}/>
                <Link to={"/login"} className={css(commonCSS.noLinkDecoration, commonCSS.alignSelfFlexEnd, styles.forgotPassword)}>Already have an account? Click here </Link>
                <ButtonMedium className={css(styles.signupButton)} text="Sign Up" color={primaryColor} onClick={handleSignups}/>
            </div>
        </div>
    </div>
}

const styles = StyleSheet.create({
    signupDiv: {
        [mobileView]: {
            flexDirection: "column"
        }
    },
    signupLeftSideView: {
        paddingTop: "1rem",
        flex: 1,
        height: "calc(100vh - 90px - 1rem)",
        [mobileView]: {
            display: "none"
        }
    },
    signupRightSideView: {
        paddingTop: "2rem",
        flex: 1,
        justifyContent: "flex-start"
    },
    signupHeaderLogo: {
        height: "10rem"
    },
    signupTextHeader: {
        width: "20rem",
        margin: "50px 0 10px 0",
        fontSize: "1.5rem",
        fontWeight: "bold",
        alignSelf: "flex-start",
    },
    signupTextSubHeader: {
        width: "20rem",
        alignSelf: "flex-start",
        color: "#222",
        margin: "0px 0 15px 0",
        fontFamily: "'Ubuntu', sans-serif"
    },
    signupButton: {
        margin: "20px 0"
    },
    forgotPassword: {
        fontSize: "0.8rem",
        color: primaryColor,
        ":any-link": {
            color: shadowColor
        }
    },
    nameDiv: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    }
})
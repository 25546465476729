import { useSelector } from "react-redux"
import { StoreSelector } from "../store/store"
import LoginPage from "../scenes/LoginPage.scenes";
import { useLocation } from "react-router-dom";

interface AdminGuardI extends React.HTMLAttributes<HTMLElement> {

}

export const AdminGuard = ({children}: AdminGuardI) => {
    const userState = useSelector((state: StoreSelector) => state.user);
    const path = useLocation().pathname
    if ( userState.id && userState.isAdmin ) {
        return <>{children}</>
    }
    else if (userState.id) {
        return <LoginPage routeBackTo={path} shouldLogout={false}></LoginPage>
    }
    else 
        return <></>
}   
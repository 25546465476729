
import { StyleSheet, css } from 'aphrodite';
import { RootContainer } from '../components/containers/rootContainer.component';
import { SectionHeader } from '../components/section/sectionHeaderBlock.component';
import { primaryColor, shadowColor } from '../theme';
import { FaInstagram, FaMailBulk, FaWhatsapp } from 'react-icons/fa';
import heroImg from "../assets/img/sanginiHeroImg.webp";
import { ImageFixedHeight } from '../components/imgContainers/imageAutoFit.component';
import { SectionBody } from '../components/section/sectionBody.component';
import { SimpleContainer } from '../components/homepage/simpleContainer.component';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px',
  },
  contactInfo: {
    margin: '10px',
  },
  link: {
    textDecoration: 'none',
    color: "#333",
    ':hover': {
      color: primaryColor,
    },
  },
  contactContent: {
    width: "325px",
    textAlign: "start"
  }
});




export default function ContactUsPage() {
    return (
        <RootContainer>
            {/* <SectionHeader align="center" text='Contact Us'></SectionHeader> */}
            <center>
            <ImageFixedHeight  height="400px" src={heroImg} alt="Sangini Jewellers"/>
            {/* add email icon using antd */}
            <SimpleContainer className={css(styles.contactContent)}>
            <p className={css(styles.contactInfo)}><FaMailBulk color={primaryColor}/> <a href="mailto:customersupport@sanginijewelers.com" className={css(styles.link)}>customersupport@sanginijewellers.com</a></p>
            <p className={css(styles.contactInfo)}><FaWhatsapp color={primaryColor}/>  <a href="https://wa.me/9415675429" className={css(styles.link)}>+91-9415675429</a></p>
            <p className={css(styles.contactInfo)}><FaInstagram color={primaryColor}/>  <a href="https://instagram.com/sanginijewelers" target="_blank" rel="noopener noreferrer" className={css(styles.link)}>@sanginijewelers</a></p>
            </SimpleContainer>
            </center>
        </RootContainer>
        
    );
}
interface productConstructorObjectInterface{
   productTagNumber: string;
   productName: string;
   description: string;
   makingCharges: number;
   timeTakenForMaking: number;
   category: string;
   subCategory: string;
   image: string;
   goldWeight: number;
   goldQuality: string;
   goldCost: number;
   goldColor: string;
   stoneType: string;
   stoneWeight: number;
   stonePieces: number;
   stoneCost: number;
   diamondType: string;
   diamondWeight: number;
   diamondQuality: number;
   diamondPieces: number;
   diamondCost: number;
   available: boolean;
   availableOnOrder: boolean;
   availableOnEmi: boolean;
}

export class ProductsDTO {
   constructor(private productData: productConstructorObjectInterface) {}

  get productTagNumber() {
      
          return this.productData.productTagNumber
  }

  get productName() {
      
          return this.productData.productName
      
  }

  get description() {
      return this.productData.description
  }

  get makingCharges() {
      return this.productData.makingCharges 
  }

  get timeTakenForMaking(){
      return this.productData.timeTakenForMaking
  }

  get category() {
      return this.productData.category
  }

  get subCategory() {
      return this.productData.subCategory
  }

  get image() {
      return this.productData.image
  }

  get goldWeight() {
      return this.productData.goldWeight
  }

  get goldQuality() {
      return this.productData.goldQuality
  }

  get goldCost(){
      return this.productData.goldCost
  }

  get goldColor(){
      return this.productData.goldColor
  }

  get stoneType() {
      return this.productData.stoneType
  }

  get stoneWeight(){
      return this.productData.stoneWeight
  }

  get stonePieces(){
      return this.productData.stonePieces
  }
  get stoneCost(){
    return this.productData.stoneCost
}
get diamondType(){
  return this.productData.diamondType
}
get diamondWeight(){
  return this.productData.diamondWeight
}
get diamondQuality(){
  return this.productData.diamondQuality
}
get diamondPieces(){
  return this.productData.diamondPieces
}
get diamondCost(){
  return this.productData.diamondCost
}
get available(){
  return this.productData.available
}
get availableOnOrder(){
  return this.productData.availableOnOrder
}
get availableOnEmi(){
  return this.productData.availableOnEmi
}
  static fromJSON(data: any): ProductsDTO {
      return new ProductsDTO({
          productTagNumber: data.productTagNumber,
          productName: data.productName, 
          description: data.description, 
          makingCharges: data.makingCharges, 
          timeTakenForMaking: data.timeTakenForMaking, 
          category: data.category, 
          subCategory: data.subCategory, 
          image: data.image, 
          goldWeight: data.goldWeight,
          goldQuality: data.goldQuality,
          goldCost: data.goldCost,
          goldColor: data.goldColor,
          stoneType: data.stoneType,
          stoneWeight: data.stoneWeight,
          stonePieces: data.stonePieces,
          stoneCost: data.stoneCost,
          diamondType: data.diamondType,
          diamondWeight: data.diamondWeight,
          diamondQuality: data.diamondQuality,
          diamondPieces: data.diamondPieces,
          diamondCost: data.diamondCost,
          available: data.available,
          availableOnOrder: data.availableOnOrder,
          availableOnEmi: data.availableOnEmi,

      })
  }

  toJson() {
          return {
   productTagNumber:this.productTagNumber,
   productName:this.productName,
   description:this.description,
   makingCharges: this.makingCharges,
   timeTakenForMaking: this.timeTakenForMaking,
   category:this.category,
   subCategory:this.subCategory,
   image:this.image,
   goldWeight: this.goldWeight,
   goldQuality:this.goldQuality,
   goldCost: this.goldCost,
   goldColor:this.goldColor,
   stoneType:this.stoneType,
   stoneWeight: this.stoneWeight,
   stonePieces: this.stonePieces,
   stoneCost: this.stoneCost,
   diamondType:this.diamondType,
   diamondWeight: this.diamondWeight,
   diamondQuality: this.diamondQuality,
   diamondPieces: this.diamondPieces,
   diamondCost: this.diamondCost,
   available:this.available,
   availableOnOrder:this.availableOnOrder,
   availableOnEmi:this.availableOnEmi,
          }
            
  }

  

}

import { createSlice } from '@reduxjs/toolkit';
import { ProductsDTO } from '../models/products';

export interface ProductStateData {
  products: {
    'productTagNumber': string;
    'productName': string;
    'description': string;
    'makingCharges': number;
    'timeTakenForMaking': number;
    'category': string;
    'subCategory': string;
    'image': string;
    'goldWeight': number;
    'goldQuality': string;
    'goldCost': number;
    'goldColor': string;
    'stoneType': string;
    'stoneWeight': number;
    'stonePieces': number;
    'stoneCost': number;
    'diamondType': string;
    'diamondWeight': number;
    'diamondQuality': number;
    'diamondPieces': number;
    'diamondCost': number;
    'available': boolean;
    'availableOnOrder': boolean;
    'availableOnEmi': boolean;
  }[];
  currentProduct: {
    loading: boolean;
    error: string | null;
    product: {
      'productTagNumber': string;
      'productName': string;
      'description': string;
      'makingCharges': number;
      'timeTakenForMaking': number;
      'category': string;
      'subCategory': string;
      'image': string;
      'goldWeight': number;
      'goldQuality': string;
      'goldCost': number;
      'goldColor': string;
      'stoneType': string;
      'stoneWeight': number;
      'stonePieces': number;
      'stoneCost': number;
      'diamondType': string;
      'diamondWeight': number;
      'diamondQuality': number;
      'diamondPieces': number;
      'diamondCost': number;
      'available': boolean;
      'availableOnOrder': boolean;
      'availableOnEmi': boolean;
    } | null;
  };
}

const initialState: ProductStateData = {
  products: [],
  currentProduct: {
    loading: false,
    error: null,
    product: null,
  },
} as ProductStateData;

type SetAddProductAction = {
  payload: {
    'productTagNumber': string;
    'productName': string;
    'description': string;
    'makingCharges': number;
    'timeTakenForMaking': number;
    'category': string;
    'subCategory': string;
    'image': string;
    'goldWeight': number;
    'goldQuality': string;
    'goldCost': number;
    'goldColor': string;
    'stoneType': string;
    'stoneWeight': number;
    'stonePieces': number;
    'stoneCost': number;
    'diamondType': string;
    'diamondWeight': number;
    'diamondQuality': number;
    'diamondPieces': number;
    'diamondCost': number;
    'available': boolean;
    'availableOnOrder': boolean;
    'availableOnEmi': boolean;
  };
  type?: string;
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setAddProduct(state: ProductStateData, action: SetAddProductAction) {
      state.products.push(action.payload);
      state.currentProduct = {
        loading: false,
        error: null,
        product: action.payload,
      };
      return state;
    },
    setLoading(state: ProductStateData, action: { payload: boolean; type?: string }) {
      state.currentProduct.loading = action.payload;
      return state;
    },
    setError(state: ProductStateData, action: { payload: string | null; type?: string }) {
      state.currentProduct.error = action.payload;
      return state;
    },
    resetProduct() {
      return initialState;
    },
  },
});

export const { setAddProduct, setLoading, setError, resetProduct } = productSlice.actions;
export default productSlice.reducer;

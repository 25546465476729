import { StyleSheet, css } from "aphrodite";
import commonCss, { margin, mobileView } from "../../commonCss/commonCss";
import { primaryColor } from "../../theme";
import { ButtonMedium } from "../button.component";
import React from "react";
import { useNavigate } from "react-router-dom";

interface HeroDisplayProps {
    header: string,
    src: string
}

export default function HeroDisplay({header, src}: HeroDisplayProps) {
  const navigate = useNavigate();
  return (
    <section className={css(commonCss.postionRelative)}>
      <div className={css(styles.heroContainer)}>
        <img className={css(styles.heroImg)} src={src} alt="Sangini Jewellers"/>
      </div>
      <div className={css(styles.floatingHighlight)}>
        <div className={css(margin("20px 0"))}>
          <span className={css(styles.highlightHeader)}>
            {header}
          </span>
        </div>
        <ButtonMedium onClick={()=>navigate("/BuyJewelleries")} size="10rem" text="Shop Now" color={primaryColor} className={css(commonCss.wspNoWrap, styles.btn)}/>
      </div>
    </section>
  )
}

const styles =  StyleSheet.create({
  heroImg: {
    height: "80vh",
    width: "auto",
    [mobileView]: {
      height: "300px"
    }
  },
  heroContainer: {
    margin: "80px 0 ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [mobileView]: {
      margin: "20px 10px"
    }
  },
  highlightHeader: {
    background: "#000",
    color: "#fff",
    display: "inline",
    fontSize: "1.6rem",
    fontWeight: 600,
    wordSpacing: 2,
    letterSpacing: 5,
    "-webkit-box-decoration-break": "clone",
    boxDecorationBreak: "clone",
    textTransform: "uppercase",
    padding: "10px",
    lineHeight: 1.8,
    [mobileView]: {
      fontSize: "1.4rem",
    }
  },
  floatingHighlight: {
    maxWidth: "45%",
    margin: "10px",
    position: "absolute",
    top: "30%",
    left: "0%",
    [mobileView]: {
      position: "relative",
      maxWidth: "80%",
      left: 0,
      margin: "15px",
    }
  },
  btn: {
    fontSize: "20px",
  }
})
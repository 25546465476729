import { StyleSheet, css } from "aphrodite"
import { shadowColor } from "../theme"
import React from "react";
import { width } from "../commonCss/commonCss";

interface ButtonI extends React.HTMLAttributes<HTMLButtonElement> {
    text: string,
    color?: string,
    size?: string,
}

export const ButtonMedium = React.memo(({text, onClick, color, size, className}: ButtonI) => {
    return <button onClick={onClick} className={css(styles.button, width(size || "5rem"), DynamicColorCSS(color)) + ` ${className}`}>
        {text}
    </button>
});

const styles = StyleSheet.create({
    button: {
        display: "block",
        padding: "1rem",
        border: "none",
        boxShadow: `1px 1px 5px ${shadowColor}`,
        ":active": {
            boxShadow: `0px 0px 1px ${shadowColor}`,
        }
    },
})

const DynamicColorCSS = (val?:string) => val ? StyleSheet.create({
    bgColor: {
        background: val
    }
}).bgColor : null;
import { css, StyleSheet} from "aphrodite";
import { padding } from "../../../commonCss/commonCss";
import { primaryColor, darkAmberColor } from "../../../theme";
import { AutoComplete } from 'antd';
import type { AutoCompleteProps } from 'antd';
const options = [
  { value: 'Burns Bay Road' },
  { value: 'Downing Street' },
  { value: 'Wall Street' },
];




export default function DiamondBilling(){
    function handleChange(value: any, option: { value: string; } | { value: string; }[]): void {
        throw new Error("Function not implemented.");
    }

    return<div className={css(styles.frame, padding('1.6rem'))}>

    <div className={css(styles.title)}>Diamond Jewellery</div>

    <div className={css(styles.divli)}>
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Jewellery Name"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={handleChange}
        />
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Certificate No."
            filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
        />
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Carat"
            filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
        />


    </div>
</div>
}



const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },

    diamond:{
        color: 'grey',
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',
    },

    title:{
        padding:'5%',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    divli:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

})
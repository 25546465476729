import { useRef, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import authRepository from "../repositories/authRepository";


export const useForgotPasswordHook = () => {
    const inputEmail = useRef("");
    const inputNewPassword = useRef("");
    const [isValidResetCode, setIsValidResetCode] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const resetCode = searchParam.get("oobCode")
    useEffect(()=>{
        authRepository.getCurrentUser().then((user)=> {
            user?.uid && navigate("/BuyJewelleries");
        })
        if(resetCode) {
            authRepository.verifyResetCode(resetCode).then(email=> {
                inputEmail.current=email;
                setIsValidResetCode(true);
            })
        }
    });
    const handleSendPasswordResetEmail = () => {
        authRepository.forgotPassword(inputEmail.current, `${window.location.origin}/login`).then(() => {
            setIsEmailSent(true);
        })
    }
    const handleResetPassword = () => {
        if(resetCode)
            authRepository.resetPassword(resetCode, inputNewPassword.current).then(()=>{
                navigate("/login")
            });
    }

    return {
        isValidResetCode,
        isEmailSent,
        inputEmail,
        inputNewPassword,
        handleSendPasswordResetEmail,
        handleResetPassword
    }
}
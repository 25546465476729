import { RootContainer } from "../components/containers/rootContainer.component";
import { ImageFixedHeight } from "../components/imgContainers/imageAutoFit.component";
import { SectionBody } from "../components/section/sectionBody.component";
import comingSoon from "../assets/img/comingsoon.svg"




export default function QRScanPage() {
    return <RootContainer>
    <SectionBody>
     <center>   
    <ImageFixedHeight height="600px" src={comingSoon} alt="Coming Soon Banner"/>
    </center>
    </SectionBody>
</RootContainer>
}
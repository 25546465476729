
import { useEffect } from "react"
import comingSoon from "../assets/img/comingsoon.svg"
import { RootContainer } from "../components/containers/rootContainer.component"
import { ImageAutoFit, ImageFixedHeight } from "../components/imgContainers/imageAutoFit.component"
import { SectionBody } from "../components/section/sectionBody.component"
import { UserRepository } from "../repositories/userRepository"


export default function BuyJewelleriesPage() {
    return <RootContainer>
        <SectionBody>
         <center>   
        <ImageFixedHeight height="600px" src={comingSoon} alt="Coming Soon Banner"/>
        </center>
        </SectionBody>
    </RootContainer>
}
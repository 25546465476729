import { useSelector } from "react-redux"
import { StoreSelector } from "../store/store"
import LoginPage from "../scenes/LoginPage.scenes";
import { useLocation } from "react-router-dom";

interface AuthGuardI extends React.HTMLAttributes<HTMLElement> {

}

export const AuthGuard = ({children}: AuthGuardI) => {
    const authState = useSelector((state: StoreSelector) => state.auth);
    const path = useLocation().pathname
    return authState.uid
        ?   <>{children}</>
        :   <LoginPage routeBackTo={path} shouldLogout={false}></LoginPage>
}
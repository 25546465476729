import { ProductsRepository} from "../../repositories/productRepository";
import { useDispatch,useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
//import { StoreSelector } from "../../store/store";
import { AppDispatch,RootState  } from "../../store/store";
import { ProductsDTO } from "../../models/products";
import { FcOpenedFolder } from "react-icons/fc";
import { IoMdCloudUpload } from "react-icons/io";
import {css,StyleSheet} from 'aphrodite';
import { margin } from "../../commonCss/commonCss";
import { useState,useRef,useCallback,useEffect} from "react";
import { setError,setLoading,setAddProduct,resetProduct} from "../../store/productSlice";
import { fireStorage } from "../../config/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const AddProduct = ()=>{
    const dispatch = useDispatch<AppDispatch>();
    const productState = useSelector((state: RootState) => state.products);
    const [productDetails, setProductDetails] = useState<Partial<ProductsDTO>>({
        productTagNumber: "",
        productName: "",
        description: "",
        makingCharges: 0,
        timeTakenForMaking: 0,
        category: "",
        subCategory: "",
        image: '', // This should be an empty string initially
        goldWeight: 0,
        goldQuality: "",
        goldCost: 0,
        goldColor: "",
        stoneType: "",
        stoneWeight: 0,
        stonePieces: 0,
        stoneCost: 0,
        diamondType: "",
        diamondWeight: 0,
        diamondQuality: 0,
        diamondPieces: 0,
        diamondCost: 0, 
        available: false,
        availableOnOrder: false,
        availableOnEmi: false,
      });
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
   
    useEffect(() => {
        console.log("Product Details State Initialized: ", productDetails);
    }, [productDetails]); 
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            setPreview(URL.createObjectURL(e.target.files[0]));
          }
        };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement|HTMLSelectElement>) => {
        const { name, value,type } = e.target;
        const inputValue = type === 'checkbox'  && e.target instanceof HTMLInputElement ? e.target.checked :
        (type === 'radio' && (name === 'diamondQuality')) ? Number(value) 
        : value;
        setProductDetails(prevDetails => ({
          ...prevDetails,
          [name]: inputValue,
        }));
      };
      const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setProductDetails(prevDetails => ({
          ...prevDetails,
          [name]: value,
        }));
      };
    const handleDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
           const file = acceptedFiles[0];
            setFile(file);
            setPreview(URL.createObjectURL(file));
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: { 'image/*': ['.jpeg', '.jpg','.png', '.gif'] },
    });
    const uploadImageToFirebase = async (file: File) => {
        const storageRef = ref(fireStorage, `products/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };

    const saveProducts = async (e: React.FormEvent) => {
        e.preventDefault();
    
        if (file && Object.keys(productDetails).length) {
          try {
            dispatch(setLoading(true));
            const imageUrl = await uploadImageToFirebase(file);
            const updatedProductDetails = { ...productDetails, image: imageUrl } as ProductsDTO;
            await ProductsRepository.addProduct(updatedProductDetails);
            dispatch(setAddProduct(updatedProductDetails));    
            alert('Product is successfully saved');
          } catch (error) {
            console.error("Error uploading image: ", error);
            if (error instanceof Error) {
                dispatch(setError(error.message));
              } else {
                dispatch(setError("An unknown error occurred."));
              }
          } finally {
            dispatch(setLoading(false));
          }
        } else {
          alert('Please upload an image.');
        }
      };
       

  const handleReset = () => {
    dispatch(resetProduct());
    setProductDetails({
      productTagNumber: '',
      productName: '',
      description: '',
      makingCharges: 0,
      timeTakenForMaking: 0,
      category: '',
      subCategory: '',
      image:'',
      goldWeight: 0,
      goldQuality: '',
      goldCost:0,
      goldColor: '',
      stoneType: '',
      stoneWeight: 0,
      stonePieces: 0,
      stoneCost: 0,
      diamondType: '',
      diamondWeight: 0,
      diamondQuality: 0,
      diamondPieces: 0, 
      diamondCost: 0,
      available: false,
      availableOnOrder: false,
      availableOnEmi: false,
    });
  };
   
 
    return(
        <>
            <div>
            <form onSubmit={saveProducts}>
                <h4 className={css(style.heading)}>Product Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>

                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Product Tagnumber</span>
                                <input 
                                  className={css(style.input)}
                                  type="text"
                                   name="productTagNumber"
                                   value={productDetails?.productTagNumber?? ""}
                                   onChange={handleInputChange}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Product Name</span>
                                <input 
                                className={css(style.input)} 
                                type="text"
                                name="productName"
                                value={productDetails?.productName??""}
                                onChange={handleInputChange} />
                            </div> 
                        </div>

                        <div className={css(style.inputBox,style.discription)}>
                          <span className={css(style.span)}>Description</span>
                          <input 
                          className={css(style.input,style.discriptionInput)}  
                          type="text"
                          name="description"
                          value={productDetails?.description??""}
                          onChange={handleInputChange} />
                        </div>

                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Making Charges (in %)</span>
                                <input className={css(style.input)} type="number" 
                                name="makingCharges"
                                value={productDetails?.makingCharges??0}
                                onChange={handleInputChange}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Time Taken For Making (in days)</span>
                                <input className={css(style.input)} name="timeTakenForMaking"  type="number" 
                                value={productDetails?.timeTakenForMaking??0}
                                onChange={handleInputChange}/>
                            </div>
                       </div>

                       <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Category</span>
                                <input className={css(style.input)}  type="text" 
                                name="category"   
                                value={productDetails?.category??""}
                                onChange={handleInputChange}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>SubCategory</span>
                                <input className={css(style.input)}  type="text" 
                                name="subCategory"
                                value={productDetails?.subCategory??""}
                                onChange={handleInputChange}/>
                            </div>
                        </div>

                    </div>

                    <div className={css(style.box2)}>
                        <span className={css(style.logoCircle)}>< FcOpenedFolder className={css(style.imageIcon)}/></span>
                        <div className={css(style.imageBody)}> 
                         <h2 className={css(style.imageTitle)}>Upload Image</h2>
                         <p className={css(style.imageDescription)}>Attach the file below</p>
                         <div {...getRootProps({ className: css(style.imageButton) })}>
                                <input  {...getInputProps()} type="file" onChange={handleFileChange} />
                                  {!preview && !isDragActive &&(
                                    <>
                                        <IoMdCloudUpload className={css(style.imageIcon)} />
                                        <span className={css(style.uploadTitle)}>Drag file here to upload</span>
                                        <span className={css(style.uploadDescription)}>Alternatively you can select a file by <br /><strong className={css(style.strong)}>Click here</strong></span>
                                    </>
                                )}
                                {isDragActive && (<p className={css(style.dragActive)}>Drop the file here...</p>)}
                                {preview && (
                                    <img src={preview} alt="Preview" className={css(style.imagePreview)} />
                                )}
                            </div>
                        </div>
                        
                    </div>
                </div>


                <h4 className={css(style.heading)}>Gold Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Gold Weight  (in gms)</span>
                                <input className={css(style.input)}  type="number" 
                                name="goldWeight"
                                value={productDetails?.goldWeight??0} 
                                onChange={handleInputChange}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Gold Quality</span>
                                <div className={css(style.Quality)}>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio"  name="goldQuality"
                                    value="18K" checked={productDetails?.goldQuality === '18K'}
                                    onChange={handleInputChange}/>
                                    <label>18K</label>
                                </div>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio" name="goldQuality"
                                    value="22K" checked={productDetails?.goldQuality === '22K'}
                                    onChange={handleInputChange}/>
                                    <label>22K</label>
                                </div>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio" name="goldQuality"
                                    value="24K" checked={productDetails?.goldQuality === '24K'}
                                    onChange={handleInputChange}/>
                                    <label>24K</label>
                                </div>  
                                </div>
                                
                            </div> 
                        </div>                
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Gold Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                    name="goldCost"
                                    value={productDetails?.goldCost??0}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Gold Color</span>
                                    <select className={css(style.input)}  name="goldColor"
                                    onChange={handleSelectChange}>
                                        <option >Select Gold Color</option>
                                        <option>Golden</option>
                                        <option>Silver</option>
                                        <option>RoseGold</option>
                                    </select>
                                    
                                </div>
                        </div>
                    </div>               
                </div>

                <h4 className={css(style.heading)}>Stone Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Stone Type</span>
                                <select className={css(style.input)}  name="stoneType"
                                 onChange={handleSelectChange}>
                                     <option>Select Stone Type</option>
                                     <option>GemStones</option>
                                     <option>other</option>
                                 </select>  
                                </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Stone Weight (in carat)</span>
                                <input className={css(style.input)}  type="number" 
                                name="stoneWeight"
                                value={productDetails?.stoneWeight??0}
                                onChange={handleInputChange}/>
                            </div>                         
                        </div>                     
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Stone Pieces </span>
                                    <input className={css(style.input)}  type="number" 
                                    name="stonePieces"
                                    value={productDetails?.stonePieces??0} 
                                    onChange={handleInputChange}/>
                                </div>

                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Stone Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                    name="stoneCost"
                                    value={productDetails?.stoneCost??0}
                                    onChange={handleInputChange}/>
                                </div>
                                
                        </div>
                    </div>            
               </div>

               <h4 className={css(style.heading)}>Diamond Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Diamond Type</span>
                                <select className={css(style.input)}
                                name="diamondType"
                                onChange={handleSelectChange}>
                                     <option>Select Diamond Type</option>
                                     <option>Ruby</option>
                                     <option>White Diamond</option>
                                     <option>other</option>
                                 </select>  
                                </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Diamond Weight (in carat)</span>
                                <input className={css(style.input)}  type="number" 
                                 name="diamondWeight"
                                 value={productDetails?.diamondWeight??0}
                                 onChange={handleInputChange}/>
                            </div>                         
                        </div>       
                        <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Diamond Quality</span>
                                <div className={css(style.Quality)}>
                                    <div className={css(style.qualityDiv)}>
                                        <input className={css(style.radioInput)}  type="radio"  name="diamondQuality" 
                                           value="1" checked={productDetails?.diamondQuality === 1}
                                         onChange={handleInputChange}/>
                                        <label>1</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                        <input className={css(style.radioInput)}  type="radio" name="diamondQuality"  
                                         value="1.25" checked={productDetails?.diamondQuality === 1.25}                                      
                                         onChange={handleInputChange}/>
                                        <label>1.25</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)}  type="radio" name="diamondQuality"
                                     value="1.50" checked={productDetails?.diamondQuality === 1.50}
                                     onChange={handleInputChange}/>
                                    <label>1.50</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)}  type="radio" name="diamondQuality"
                                     value="2" checked={productDetails?.diamondQuality === 2}
                                     onChange={handleInputChange}/>
                                    <label>2</label>
                                    </div>
                                    
                                </div>
                                
                            </div>              
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Diamond Pieces </span>
                                    <input className={css(style.input)}  type="number" 
                                    name="diamondPieces"
                                    value={productDetails?.diamondPieces??0} 
                                     onChange={handleInputChange}/>
                                </div>

                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>diamond Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                     name="diamondCost"
                                     value={productDetails?.diamondCost??0}
                                     onChange={handleInputChange}/>
                                </div>
                                
                        </div>
                    </div>            
               </div>   
               <h4 className={css(style.heading)}>Availability</h4>
                
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <label>
                                <input type="checkbox" name="available" checked={productDetails?.available??false} onChange={handleInputChange} />
                                Available
                            </label>
                            <label>
                                <input type="checkbox" name="availableOnOrder" checked={productDetails?.availableOnOrder??false} onChange={handleInputChange} />
                                Available On Order
                            </label>
                            <label>
                                <input type="checkbox" name="availableOnEmi" checked={productDetails?.availableOnEmi??false} onChange={handleInputChange} />
                                Available On EMI
                            </label>
                        
                       </div> 
              </div>    
              </form> 
           </div>
             {/* {productState.loading && <p>Loading...</p>}
            {productState.error && <p>Error: {productState.error}</p>}
            {productState.productName && <p>Product Added Successfully!</p>}  */}
           <div className={css(style.buttonDiv)}>
             <button onClick = {handleReset} className={css(style.SubmitButton)}>Submit</button>
           </div>
        </>
    )
    

}
const style = StyleSheet.create({
    parent:
    {
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        columnGap:'10px',
    },
    heading:{
        fontWeight:'bolder',
        fontSize:'20px'
    },
    box1:{
        
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
    },
    row:{
        display:'flex',
        justifyContent:'space-between',
    },
    inputBox:{
        display:'flex',
        flexDirection:'column',
        width:'47%',
        // height:'50px',
        marginBottom:'20px',
        },
    input:{
        position:'relative',
        width:'100%',
        border:'2px solid #aaa',
        outline:'none',
        background:'none',
        padding:'10px',
        borderRadius:'10px',
        fontSize:'18px',
        marginTop:'10px',
        boxShadow:'0px 1px 5px #aaa'

},
span:{
    
    fontSize:'18px',
},
discription:{
    width:'100%',
},
discriptionInput:{
    height:'80px',
},
    box2:
    {
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        paddingBottom:'0px',
    },
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        marginTop:'1.25rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'3rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
            padding: '1rem 1.5rem 1.5rem',
            display: 'flex',
            justifyContent:'flex-end',           
    },
    button:{
        padding:'.5rem 1rem',
        fontWeight: 'bold',
        border: '2px solid #aaa',
        borderRadius: '.25rem',
        marginLeft: '.75rem',
        boxShadow:'0px 1px 5px #aaa'
    },
    cancelButton:{
        backgroundColor:'transparent'
    },
    uploadButton:{
        backgroundColor:'#f0bf4c',
        marginRight: '-1.5rem',
    },
    Quality:
    {
        ':first-of-type':{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'5px',
        marginTop:'15px'
    }
        
    },
   qualityDiv:{
    marginRight:'10px'
   },
   radioInput:{
    appearance:'none',
    borderRadius:'50%',
    width:'16px',
    height:'16px',
    border:'2px solid #aaa',
    marginRight:'5px',
    position:'relative',            
    boxShadow:'0px 1px 5px #aaa',
    ':checked':{
            border: '6px solid black',   
    }
   },
   label:{
    marginRight:'20px',
    lineHeight:'32px',
   },
   buttonDiv:{
    marginTop:'30px',
    display:'flex',
    justifyContent:'center',
   
   },
   SubmitButton:{
    alignItems:'center',  
    padding:'10px',
    fontSize:'18px',
    outline:'none',
    border:'none',
    borderRadius:'10px',
    width:'200px',
    boxShadow:'0px 1px 10px #BF9850',
    backgroundColor:'#f0bf4c'
   },
   availability:{
    width:'100%'
   }
})
export default AddProduct
import { ButtonMedium } from "../components/button.component";
import { Input } from "../components/input.component";
import {primaryColor, shadowColor} from "../theme"
import commonCSS, { mobileView, width } from "../commonCss/commonCss"
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as ForgotPasswordSVG } from "../forgotPassword.svg"
import logo from '../assets/img/sangini_black.png'
import { useForgotPasswordHook } from "../hooks/useForgotPasswordHook";


export const ForgotPasswordPage = () => {
    const {
        isValidResetCode,
        isEmailSent,
        inputEmail,
        inputNewPassword,
        handleResetPassword,
        handleSendPasswordResetEmail } = useForgotPasswordHook();
    
    return <div className={css(commonCSS.flexRow, styles.loginDiv)}>
        <ForgotPasswordSVG className={css(styles.loginLeftSideView)}></ForgotPasswordSVG>
        <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter, styles.loginRightSideView)}>
            <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter)}>
                <img src={logo} alt="Sangini Jewellers" className={css(styles.loginHeaderLogo)} />
                {
                    !isValidResetCode && !isEmailSent
                    ?   <>
                            <div className={css(styles.loginTextHeader)}>Forgot Password?</div>
                            <div className={css(styles.loginTextSubHeader)}>Enter you email</div>
                            <Input className={css(commonCSS.marginTB10)} type="text" placeholder="Enter email" autoFocus={true} onChangeValue={(val) => {inputEmail.current = val}}/>
                            <ButtonMedium className={css(styles.loginButton)} text="Continue" color={primaryColor} onClick={handleSendPasswordResetEmail}/>
                        </>
                    :   isValidResetCode
                        ?   <>
                                <div className={css(styles.loginTextHeader)}>Reset Password</div>
                                <div className={css(styles.loginTextSubHeader)}>Enter New Password</div>
                                <Input className={css(commonCSS.marginTB10)} type="password" placeholder="Enter Password" autoFocus={true} onChangeValue={(val) => {inputNewPassword.current = val}}/>
                                <ButtonMedium className={css(styles.loginButton)} text="Done" color={primaryColor} onClick={handleResetPassword}/>
                            </>
                        :   <div className={css(commonCSS.flexRow, commonCSS.justifyCenter, commonCSS.alignItemsCenter, styles.sentEmail, width("20rem"))}>
                                Email has been sent to {inputEmail.current}, <br/>
                                Please check your email and follow the instruction to reset your password
                            </div>
                }
                
            </div>
        </div>
    </div>
}

const styles = StyleSheet.create({
    loginDiv: {
        [mobileView]: {
            flexDirection: "column"
        }
    },
    loginLeftSideView: {
        paddingTop: "1rem",
        flex: 1,
        height: "calc(100vh - 90px - 1rem)",
        [mobileView]: {
            display: "none"
        }
    },
    loginRightSideView: {
        paddingTop: "2rem",
        flex: 1,
        justifyContent: "flex-start"
    },
    loginHeaderLogo: {
        height: "10rem"
    },
    loginTextHeader: {
        width: "20rem",
        margin: "50px 0 10px 0",
        fontSize: "1.5rem",
        fontWeight: "bold",
        alignSelf: "flex-start",
    },
    loginTextSubHeader: {
        width: "20rem",
        alignSelf: "flex-start",
        color: "#222",
        margin: "0px 0 15px 0",
        fontFamily: "'Ubuntu', sans-serif"
    },
    loginButton: {
        margin: "20px 0"
    },
    forgotPassword: {
        fontSize: "0.8rem",
        color: primaryColor,
        ":any-link": {
            color: shadowColor
        }
    },
    sentEmail: {
        padding: "50px",
        color: "red",
        textAlign: "center"
    }
})

interface RateConstructorObjectInterface {
    _18K: number, _22K: number, _24K: number, _24K995: number, _silver35: number, _silver50: number, _silver90: number, _silver100: number
}


export class GoldRateDTO {

    private constructor(private rateData: RateConstructorObjectInterface) {}

    get rateOf18K() {
        return this.rateData._18K;
    }

    get rateOf22K() {
        return this.rateData._22K;
    }

    get rateOf24K() {
        return this.rateData._24K;
    }

    get rateOf24K995() {
        return this.rateData._24K995;
    }

    get rateOfSilver35(){
        return this.rateData._silver35;
    }

    get rateOfSilver50(){
        return this.rateData._silver50;
    }

    get rateOfSilver90(){
        return this.rateData._silver90;
    }

    get rateOfSilver100(){
        return this.rateData._silver100;
    }

    static fromJSON(data: any) {
        return data?.gold ? new GoldRateDTO({
            _18K: data.gold['18K'],
            _22K: data.gold['22K'],
            _24K: data.gold['24K'],
            _24K995: data.gold['24K995'],
            _silver35: data.silver[0],
            _silver50: data.silver[1],
            _silver90: data.silver[2],
            _silver100: data.silver[3]
        }) : new GoldRateDTO({
            _18K: 0,
            _22K: 0,
            _24K: 0,
            _24K995: 0,
            _silver35: 0,
            _silver50: 0,
            _silver90: 0,
            _silver100: 0
        });
    }

    toJson() {
        return {
            gold: {
                '18K': this.rateOf18K,
                '22K': this.rateOf22K,
                '24K': this.rateOf24K,
                '24K995': this.rateOf24K995,
            },
            silver: [this.rateOfSilver35, this.rateOfSilver50, this.rateOfSilver90, this.rateOfSilver100]
            
        }
    }

}
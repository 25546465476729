import { createSlice } from "@reduxjs/toolkit";


export interface UserDetails {
    name:string,
    isAdmin: boolean,
    id: string
}

const initialState = {}

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser (state, action) {
            state = {
                ...action.payload
            }
            return state;
        },
        clear (state, action) {
            return initialState;
        }
    },
})

export const {setUser, clear} = authSlice.actions;
export default authSlice.reducer;
import { css } from "aphrodite"
import commonCss from "../../commonCss/commonCss"

interface sectionBodyProps extends React.AllHTMLAttributes<HTMLElement>{
  center?: boolean
}

export const SectionBody = ({children, center}: sectionBodyProps)=>{
    return <section className={css(commonCss.grid, commonCss.autoGridTemplate, commonCss.padding10, center? commonCss.textAlignCenter : null)}>
      {children}
    </section>
}
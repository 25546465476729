import * as firebaseAuth from "firebase/auth"
import { app } from "../config/firebase"

const auth = firebaseAuth.getAuth(app);

const Auth = () => {
    return {
        async simpleLogin(email: string, password: string): Promise<firebaseAuth.User> {
            return (await firebaseAuth.signInWithEmailAndPassword(auth, email, password)).user;
        },
        async logout() {
            return await firebaseAuth.signOut(auth);
        },
        async signup(email: string, password: string) {
            return await firebaseAuth.createUserWithEmailAndPassword(auth, email, password)
        },
        async getCurrentUser(): Promise<firebaseAuth.User | null> {
            return new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user)=>{
                    resolve(user);
                }, error => {
                    reject(error)
                })
            })
            
        },
        async forgotPassword(email: string, url?: string) {
            return url
            ?   await firebaseAuth.sendPasswordResetEmail(auth, email, {
                    url,
                    
                })
            :   await firebaseAuth.sendPasswordResetEmail(auth, email);
        },
        parseUser(user: firebaseAuth.User) {
            return {
                displayName: user.displayName,
                uid: user.uid,
                photoURL: user.photoURL,
                email: user.email,
                emailVerified: user.emailVerified
            }
        },
        async resetPassword(code: string, newPassword: string) {
            return await firebaseAuth.confirmPasswordReset(auth, code, newPassword);
        },
        async verifyResetCode(code: string) {
            return await firebaseAuth.verifyPasswordResetCode(auth, code);
        }
    }
}

export default Auth();
import { css , StyleSheet} from "aphrodite";
import commonCss, { padding, colsGap, rowsGap } from "../../commonCss/commonCss";
import { darkAmberColor, primaryColor, silverColor } from "../../theme";
import { AutoComplete } from 'antd';
import type { AutoCompleteProps } from 'antd';
import GoldBilling from "./billingsubcomponents/goldbilling.component";
import SilverBilling from "./billingsubcomponents/silverbilling.component";
import DiamondBilling from "./billingsubcomponents/diamondbilling.component";
const options = [
  { value: 'Burns Bay Road' },
  { value: 'Downing Street' },
  { value: 'Wall Street' },
];


  




export default function Billing(){
    return<div className={css(commonCss.flexColumn, rowsGap("30px"))}>
        <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
            <div className={css(styles.frame, padding('1.6rem'))}>

                    <div className={css(styles.title)}>Customer Details</div>  

                    <div className={css(styles.divli,commonCss.justifySpaceAround)}>

                        <AutoComplete
                            style={{ width: 'auto' }}
                            options={options}
                            placeholder="Name"
                            filterOption={(inputValue, option) =>
                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <AutoComplete
                            style={{ width: 'auto' }}
                            options={options}
                            placeholder="Contact"
                            filterOption={(inputValue, option) =>
                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <AutoComplete
                            style={{ width: 'auto' }}
                            options={options}
                            placeholder="Address"
                            filterOption={(inputValue, option) =>
                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />

                    </div>
            </div>
        </div>




        <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
            <GoldBilling />
            <SilverBilling />
            <DiamondBilling />
        </div>

    </div>

}



const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },
    
    title:{
        padding:'5%',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    divli:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

})
import { ButtonMedium } from "../components/button.component";
import { Input } from "../components/input.component";
import {primaryColor, shadowColor} from "../theme"
import commonCSS, { mobileView } from "../commonCss/commonCss"
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as ShoppingSVG } from "../gone_shopping.svg"
import logo from '../assets/img/sangini_black.png'
import { Link } from "react-router-dom";
import { useLoginHook } from "../hooks/useLoginHook";

export default function LoginPage({routeBackTo, shouldLogout}: {routeBackTo?: string, shouldLogout?: boolean}) {
    const {inputEmail, inputPassword, handleLogin} = useLoginHook({routeBackTo, shouldLogout});

    return <div className={css(commonCSS.flexRow, styles.loginDiv)}>
        <ShoppingSVG className={css(styles.loginLeftSideView)}></ShoppingSVG>
        <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter, styles.loginRightSideView)}>
            <div className={css(commonCSS.flexColumn, commonCSS.alignItemsCenter)}>
                <img src={logo} alt="Sangini Jewellers" className={css(styles.loginHeaderLogo)} />
                <div className={css(styles.loginTextHeader)}>Login</div>
                <div className={css(styles.loginTextSubHeader)}>Please Sign in to Continue</div>
                <Input className={css(commonCSS.marginTB10)} type="text" placeholder="Enter email" autoFocus={true} onChangeValue={(val) => {inputEmail.current = val}}/>
                <Input className={css(commonCSS.marginTB10)} type="password" placeholder="Enter password" onChangeValue={(val) => {inputPassword.current = val}}/>
                <Link to={"/forgotPassword"} className={css(commonCSS.noLinkDecoration, commonCSS.alignSelfFlexEnd, styles.forgotPassword)}>Forgot password? </Link>
                <Link to={"/signup"} className={css(commonCSS.noLinkDecoration, commonCSS.alignSelfFlexEnd, styles.forgotPassword)}>Don't have an account? click here </Link>
                <ButtonMedium className={css(styles.loginButton)} text="Login" color={primaryColor} onClick={handleLogin}/>
            </div>
        </div>
    </div>
}

const styles = StyleSheet.create({
    loginDiv: {
        [mobileView]: {
            flexDirection: "column"
        }
    },
    loginLeftSideView: {
        paddingTop: "1rem",
        flex: 1,
        height: "calc(100vh - 90px - 1rem)",
        [mobileView]: {
            display: "none"
        }
    },
    loginRightSideView: {
        paddingTop: "2rem",
        flex: 1,
        justifyContent: "flex-start"
    },
    loginHeaderLogo: {
        height: "10rem"
    },
    loginTextHeader: {
        width: "20rem",
        margin: "50px 0 10px 0",
        fontSize: "1.5rem",
        fontWeight: "bold",
        alignSelf: "flex-start",
    },
    loginTextSubHeader: {
        width: "20rem",
        alignSelf: "flex-start",
        color: "#222",
        margin: "0px 0 15px 0",
        fontFamily: "'Ubuntu', sans-serif"
    },
    loginButton: {
        margin: "20px 0"
    },
    forgotPassword: {
        fontSize: "0.8rem",
        color: primaryColor,
        ":any-link": {
            color: shadowColor
        }
    }
})
import { css, StyleSheet} from "aphrodite";
import { padding } from "../../../commonCss/commonCss";
import { primaryColor, darkAmberColor } from "../../../theme";
import { AutoComplete } from 'antd';
import type { AutoCompleteProps } from 'antd';
import { useRef } from "react";
const options = [
  { value: 'Burns Bay Road' },
  { value: 'Downing Street' },
  { value: 'Wall Street' },
];


export default function GoldBilling(){

    const name = useRef('')
    const tag = useRef('')
    const karat = useRef('')
    const weight = useRef('')

    const goldItems = [];

    function addItem(){
        goldItems.push({name: name.current, tag: tag.current, karat: karat.current, weight: weight.current})
    }

    return<div className={css(styles.frame, padding('1.6rem'))}>

    <div className={css(styles.title)}>Gold Jewellery</div>

    <div className={css(styles.dataentry)}>
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Jewellery Name"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => name.current=value}
        />
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Jewellery Tag"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => tag.current=value}
        />
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Karat"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => karat.current=value}
        />
        <AutoComplete
            style={{ width: 'auto' }}
            options={options}
            placeholder="Weight"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => weight.current=value}
        />
    </div>
    <div className={css(styles.dataentry)}>

    </div>

</div>
}

const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },

    gold:{
        color: darkAmberColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },

    title:{
        padding:'5%',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    dataentry:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        // fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },


})
import React, { useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';

const TermsAndConditions = () => {
    useEffect(() => {
        //this function is used for rendering the page from top.
        window.scrollTo(0, 0);
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
        const termsLink = document.getElementById('termsLink');
        if (termsLink) {
            termsLink.addEventListener('click', scrollToTop);
        }
        return () => {
            if (termsLink) {
                termsLink.removeEventListener('click', scrollToTop);
            }
        };
    }, []);

    return (
        <div className={css(styles.termsContainer)}>
            <h1>Terms and Conditions</h1>

            <section className={css(styles.section)}>
                <h2>Use of Our Website</h2>
                <p>
                    When you use this website and place orders through it, you agree to:
                </p>
                <ol className={css(styles.list)}>
                    <li>Use this website to make enquiries and legally valid orders only.</li>
                    <li>Not to make any false or fraudulent orders. If an order of this type may reasonably be considered to have been placed, we shall be authorised to cancel it and inform the competent authorities.</li>
                    <li>Provide us with your email address, postal address, and/or other contact details truthfully and exactly.</li>
                    <li>If you do not provide us with all the information we need, you cannot place your order.</li>
                </ol>
            </section>

            <section className={css(styles.section)}>
                <h2>Contract</h2>
                <p>
                    After you place an order, you will receive Confirmation mail/Message (Order Confirmation) and (Shipping Confirmation).
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Availability of Products</h2>
                <p>
                    All orders are subject to the availability of Products. If there are difficulties regarding the supply of products or there are no more items left in stock, we reserve the right to provide you with information on substitute products of the same. If you do not wish to order the substitute products, we will reimburse any amount that you may have paid.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Refusal to Process an Order</h2>
                <p>
                    We will always do everything possible to process all orders. However, there may be exceptional circumstances that force us to refuse to process an order after having sent the Order Confirmation. We reserve the right to do so at any time. We reserve the right to remove any product from this website at any time and to remove or modify any material or content from the same. We shall not be liable to you or any third party for removing any product or modifying any product or material or content from our website or not processing an order once we have sent the Order Confirmation.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Delivery</h2>
                <p>
                    In continuation to clause (3) above regarding the availability of the product and extraordinary circumstances, we will try to make sure to send the order consisting of Product(s) by the date indicated in the Delivery confirmation or if no delivery date is specified, in the estimated time frame within a maximum period of 30 days from the date of Order confirmation. Nonetheless, there may be delays for reasons such as the occurrence of unforeseen circumstances or the delivery zone.
                </p>
                <p>
                    For the purpose of these Conditions, the “delivery” shall be understood to have taken place or the order “delivered” as soon as you or a third party indicated by you acquires physical possession of the goods, which will be evidenced by the signing of the receipt of the order at the delivery address indicated by you.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Price and Payment</h2>
                <p>
                    We make every effort to ensure that the prices featured on the website are correct; errors may occur. If we discover an error in the price of any of the products that you have ordered, we will inform you as soon as possible and give you the option of confirming your order at the correct price or cancelling it. If we are unable to contact you, the order will be considered canceled, and all amounts paid will be refunded to you in full. We are not obliged to provide you with any product at the incorrect lower price (even when we have sent the Shipping Confirmation) if the error in the price is obvious and unmistakable and could have reasonably been recognized by you as an incorrect price. The prices on the website exclude Goods and Service Tax (GST), duties, cesses, and delivery charges. Prices may change at any time.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Invoice</h2>
                <p>
                    Invoice will be provided to you along with the products when delivered.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Taxes</h2>
                <p>
                    Pursuant to the prevailing rules and regulations in force, all purchases done through the website are subject to all applicable taxes, including but not limited to GST, duties, cesses, etc.
                </p>
            </section>

            <section className={css(styles.section)}>
                <h2>Exchange/Return/Refund Policy</h2>
                <p>
                    We grant you a period of 15 days from the day the order was delivered by the delivery executive (for orders placed outside the sale duration). In case you return the goods within the contractual term of the right of withdrawal, you will only be reimbursed with the amount paid for said products. Delivery charges will not be reimbursed after the goods are returned. The amount will be credited back into the customer’s store wallet. In case you have received a damaged, defective, missing, or a wrong product on delivery, please reach out to us within 24 hours at <a href="mailto:info@sanginijewellers.com" id="termsLink" className={css(styles.emailLink)}>info@sanginijewellers.com</a>. Exchange of a product is subject to stock availability. An order can be returned/exchanged to a maximum of two (2) consecutive times.
                </p>
            </section>

            {/* Continue adding sections based on the content */}
        </div>
    );
};

export default TermsAndConditions;

const styles = StyleSheet.create({
    termsContainer: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
        margin: '20px auto',  // Centering is now only applied horizontally
        maxWidth: '800px',
    },

    section: {
        marginBottom: '15px',
    },

    list: {
        listStyleType: 'decimal',
        marginLeft: '20px',
    },

    emailLink: {
        color: 'black',
        fontWeight: 'bold',
        textDecoration: 'none', // Remove underline from the link
        cursor: 'pointer', // Add pointer cursor for better user experience
    },
});

import heroImg from "../assets/img/sanginiHeroImg.webp";
import { StyleSheet, css } from "aphrodite";
import HeroDisplay from "../components/homepage/heroDisplay.component";
import { SectionHeader } from "../components/section/sectionHeaderBlock.component";
import { SectionBody } from "../components/section/sectionBody.component";
import { TileCard } from "../components/cards/tilecard.component";
import commonCss, { mobileView } from "../commonCss/commonCss";
import { SimpleContainer, SimpleContent, SimpleHeader } from "../components/homepage/simpleContainer.component";
import { Input } from "../components/input.component";
import { ButtonMedium } from "../components/button.component";
import { primaryColor } from "../theme";
import { RootContainer } from "../components/containers/rootContainer.component";
import ring from "../assets/img/categories/ring.png";
import earring from "../assets/img/categories/earring.png";
import mangalsutra from "../assets/img/categories/mangalsutra.png";
import nosering from "../assets/img/categories/nosering.png";
import chain from "../assets/img/categories/chain.png";
import bracelets from "../assets/img/categories/bracelets.png";
import ranihaar from "../assets/img/categories/ranihaar.png";
import longhaar from "../assets/img/categories/longhaar.png";
import necklaceset from "../assets/img/categories/necklaceset.png";
import toerings from "../assets/img/categories/toerings.png";
import payal from "../assets/img/categories/payal.png";
import kanthi from "../assets/img/categories/kanthi.png";
import locket from "../assets/img/categories/locket.png";
import maangtika from "../assets/img/categories/maangtika.png";
import nathiya from "../assets/img/categories/nathiya.png";
import anklets from "../assets/img/categories/anklets.png";
import bangles from "../assets/img/categories/bangles.png";
import pendant from "../assets/img/categories/pendant.png";
import { ImageFixedHeight, ImageFixedSize } from "../components/imgContainers/imageAutoFit.component";

export default function HomePage() {
  const shopByCategories = [{img: ring, name: "Ring"}, {img: earring, name: "Earrings"}, {img: chain, name: "Chain"}, {img: bracelets, name: "Bracelets"}, {img: ranihaar, name: "Rani Haar"}, {img: longhaar, name: "Long Haar"}, {img: necklaceset, name:  "Necklace Set"}, {img: kanthi, name: "Kanthi"}, {img: mangalsutra, name: "Mangalsutra"}, {img: pendant, name: "Pendant"}, {img: bangles, name: "Bangles"}, {img: locket, name: "Locket"}, {img: nosering, name: "Nose Ring"}, {img: nathiya, name: "Nathiya"}, {img: maangtika, name: "Maangtika"}, {img: anklets, name: "Anklets"}, {img: payal, name: "Payal"}, {img: toerings, name: "Toe Rings"}];
  const highlightHeader = "Now upgrade your jewellery collection at even lower costs";
  return (
    <RootContainer>
      <HeroDisplay header={highlightHeader} src={heroImg}></HeroDisplay>
      <SectionHeader text="Shop By Categories" align="center"></SectionHeader>
      <SectionBody>
        {shopByCategories.map(category => <TileCard key={category.name}>
          <ImageFixedHeight src={category.img} alt="Ring" height="10em" />
          <p>{category.name}</p>
          </TileCard>
        )}
      </SectionBody>
      <SectionHeader text="About Us" align="center"></SectionHeader>
      <div className={css(styles.grid)}>
        <SimpleContainer className={css(styles.abuc)}>
          <SimpleHeader>
            Above and Beyond Quality
          </SimpleHeader>
          <SimpleContent>
            Are you looking for stunning, long-lasting jewelry? Quality should never be a compromise. While our focus is expertly crafted wedding rings, no matter what type of jewelry you're looking for, we design our pieces to be unique, durable and attractive.
          </SimpleContent>
        </SimpleContainer>
        <SimpleContainer className={css(styles.abuc1)}>
          <SimpleHeader>
            Cultivating Sparkling Relationships
          </SimpleHeader>
          <SimpleContent>
          When you order from us, whether it's your first or tenth time, you become part of our family. During your search for the perfect gemstone or setting, we help you through the process.
          </SimpleContent>
        </SimpleContainer>
        <SimpleContainer className={css(styles.abuc2)}>
          <SimpleHeader>
            Our Promise
          </SimpleHeader>
          <SimpleContent>
            We treat our customers with the honesty and respect they deserve. We want clients to feel the same pride when wearing the pieces from our store that we do in making them. 
          </SimpleContent>
        </SimpleContainer>
      </div>
      <footer>
        <div className={css(styles.subscribeContainer)}>
          <div className={css(styles.keepInTouch)}>Keep In Touch</div>
          <div className={css(styles.subscribeInp)} ><Input widthVal={"calc(100% - 30px)"} onChangeValue={()=>{}} type="text" placeholder="Enter Email Address"></Input></div>
          <div className={css(commonCss.margin10, commonCss.padding10)} ><ButtonMedium text="Subscribe" color={primaryColor} size="100px"></ButtonMedium></div>
        </div>
      </footer>
    </RootContainer>
  )
}

const styles =  StyleSheet.create({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    padding: "10px",
    [mobileView]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    }
  },
  abuc: {
    gridColumn: "1/-1",
    gridRow: "1"
  },
  abuc1: {
    gridRow: 2,
    gridColumn: "1/span 2",
    [mobileView]: {
      gridColumn: "1/span all",
    }
  },
  abuc2: {
    gridRow: 2,
    gridColumn: "3/span 2",
    [mobileView]: {
      gridRow: 3,
      gridColumn: "1/span all",
    }
  },
  subscribeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    [mobileView]: {
      flexDirection: "column",
    }
  },
  keepInTouch: {
    margin: "10px",
    padding: "10px",
    fontSize: "1.5rem",
    fontWeight: 600,
    letterSpacing: "0.1rem",
    textTransform: "uppercase",
    [mobileView]: {
      fontSize: "1rem",
    }
  },
  subscribeInp: {
    margin: "10px",
    padding: "10px",
    flex: 4,

    [mobileView]: {
      margin: "0",
      padding: "0",
      width: "calc(100% - 40px)",
    }
  }
})
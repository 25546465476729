import { useState } from "react";


export const useNavbarHook = () => {
    const [isBreadCrumbOpened, setIsBreadCrumbOpened] = useState(false);
    const handleNavAction = () => { // function to handle click on Nav items
        setIsBreadCrumbOpened(false);
    };
    const handleBreadCrumbToggle = (val?: boolean) => {
        setIsBreadCrumbOpened( val!==undefined ? val : !isBreadCrumbOpened);
    }
    return {isBreadCrumbOpened, handleNavAction, handleBreadCrumbToggle}
}
import { updateProfile } from "firebase/auth";
import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authRepository from "../repositories/authRepository";
import { logout, login } from "../store/authSlice";

export const useSignUpHook = () => {
    const inputFirstName = useRef("");
    const inputLastName = useRef("");
    const inputEmail = useRef("");
    const inputPassword = useRef("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        authRepository.logout().then(()=> {
            dispatch(logout());
        })
    }) ;
    const handleSignups = () => {
        authRepository.signup(inputEmail.current, inputPassword.current).then(async (userCred) => {
            await updateProfile(userCred.user, {
                displayName: `${inputFirstName.current} ${inputLastName.current}`
            })
            dispatch(login(authRepository.parseUser(userCred.user)))
            navigate("/BuyJewelleries")
        })
    }

    return {
        inputFirstName,
        inputLastName,
        inputEmail,
        inputPassword,
        handleSignups
    }
}
import { css, StyleSheet } from "aphrodite"
import commonCss, { margin, mobileView } from "../../commonCss/commonCss"

interface SimpleContainerProps extends React.AllHTMLAttributes<HTMLElement>{
}

export const SimpleContainer = ({children, className}: SimpleContainerProps) => {
    return <article className={`${css(commonCss.margin10)} ${className}`}>
        {children}        
    </article>
}
export const SimpleHeader =  ({children}: SimpleContainerProps) => {
    return <header className={css(commonCss.flexColumn, commonCss.justifyCenter, commonCss.alignItemsCenter)}>
        <div className={css(styles.aboutUsItemHeader)}>
            {children}
        </div>
    </header>
}

export const SimpleContent =  ({children}: SimpleContainerProps) => {
    return <section className={css(commonCss.flexColumn, commonCss.justifyCenter, commonCss.alignItemsCenter, margin("20px 0"), styles.aboutUsItemContent)}>
        {children}
    </section>
}

const styles =  StyleSheet.create({
  aboutUsItemHeader: {
    display: "inline-block",
    fontSize: "20px",
    fontWeight: 600,
    padding:"20px 0",
    borderBottom: "1px solid #ccc",
    textAlign: "center",
    [mobileView]: {
      fontSize: "16px"
    }   
  },
  aboutUsItemContent: {
    fontSize: "17px",
    textAlign: "center",
    color: "#666",
    [mobileView]: {
      fontSize: "14px"
    }
  }
})
import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authRepository from "../repositories/authRepository";
import { logout, login } from "../store/authSlice";
import { UserRepository } from "../repositories/userRepository";
import { setUser } from "../store/userSlice";



export const useLoginHook = ({routeBackTo, shouldLogout}: {routeBackTo?: string, shouldLogout?: boolean}) => {
    const inputEmail = useRef("");
    const inputPassword = useRef("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        shouldLogout && authRepository.logout().then(()=> {
            dispatch(logout());
        })
    });
    const handleLogin = () => {
        authRepository.simpleLogin(inputEmail.current, inputPassword.current).then(user => {
            UserRepository.getUserDetails(user.uid).then(userData => {
                dispatch(setUser(userData?.toJson()));
            });
            dispatch(login(authRepository.parseUser(user)));
            navigate(routeBackTo || "/BuyJewelleries");
        })
    }
    return {
        inputEmail,
        inputPassword,
        handleLogin
    }
}
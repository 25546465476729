import { StyleSheet, css } from "aphrodite";
import { primaryColor } from "../theme";
import React from "react";
import { width } from "../commonCss/commonCss";

interface InputI extends React.HTMLAttributes<HTMLInputElement> {
  value?: string;
  type: string;
  placeholder?: string;
  onChangeValue: (val: string) => void;
  widthVal?: string;
}

export const Input = React.memo(
  ({
    type,
    value,
    placeholder,
    className,
    autoFocus,
    onChangeValue,
    widthVal,
  }: InputI) => {
    return (
      <input
        type={type}
        onChange={(e) => onChangeValue(e.target.value)}
        autoFocus={autoFocus}
        value={value}
        className={
          css(styles.input, width(widthVal || "20rem")) + ` ${className}`
        }
        placeholder={placeholder}
      />
    );
  }
);

const styles = StyleSheet.create({
  input: {
    padding: "0.8rem",
    outlineColor: primaryColor,
  },
});

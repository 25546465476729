import { Query } from "../library/query";
import { ProductsDTO } from "../models/products";
//import { setDoc } from "firebase/firestore";

export class ProductsRepository {
  private static productCollection = Query.getCollection('products');


  static async getAllProducts(): Promise<Array<ProductsDTO>> {
      const products = await this.productCollection.getAllDocs();
      const productsDTOArr: Array<ProductsDTO>=[]
    products.forEach(product =>{
      productsDTOArr.push(ProductsDTO.fromJSON(product))
    })
    return productsDTOArr
  }

  static async addProduct(data: ProductsDTO) {
    try {
      const productData = data.toJson();
      const docRef = await this.productCollection.addNewDoc({ ...productData });
      console.log("Product added with ID:", docRef.id);
      return docRef;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error;
    }
  }
  static async editProduct(id: string, data: any) {
    console.log("Editing product with id and data:", id, data); // Log id and data
    if (!id || !data) {
      throw new Error("ID or data is empty or invalid");
    }
    return await this.productCollection.updateDocById(id, { ...data });
  }

  static async deleteProduct(id: string) {
    console.log("Deleting product with id:", id); // Log the id being deleted
    if (!id) {
      throw new Error("ID is empty or invalid");
    }
    return await this.productCollection.removeDocById(id);
  }
}
import { StyleSheet, css } from "aphrodite"
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

interface BreadCrumbI {
    className?: string,
    onClick: (val?: boolean) => void
}

export const BreadCrumb = React.memo(({className, onClick}: BreadCrumbI) => {
    return <div className={css(styles.breadCrumb) + ` ${className}`} onClick={() => onClick()}>
        <GiHamburgerMenu></GiHamburgerMenu>
    </div>
});

const styles = StyleSheet.create({
    breadCrumb: {
        display: "block",
        background: "transparent",
        padding: "5px",
        height: "100%",
        fontSize: "1.5rem"
    },
    span: {
        display: "block",
        width: '30px',
        height: '10px',
        background: "#000"
    }
})
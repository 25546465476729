import { StyleSheet, css } from "aphrodite"
import { useContext } from "react";
import { NavContext } from "./navbar.component";
import { FaRupeeSign } from "react-icons/fa";
import { formatToINR } from "../library/dataTransformer";
import { primaryColor } from "../theme";
import { Dropdown, MenuProps } from "antd";
import { useGoldRateHook } from "../hooks/useGoldRateHook";
import { useSelector } from "react-redux";
import { StoreSelector } from "../store/store";
import { GoldRateDTO } from "../models/goldRate";
import { GoldRateStateData } from "../store/goldRateSlice";

const RateItems: (rate: GoldRateDTO) => MenuProps['items'] = (rate) => ([
    {
      key: '1',
      label: (
        <div>
          18Karat: {formatToINR(rate?.rateOf18K || 0)}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div>
          22Karat: {formatToINR(rate?.rateOf22K || 0)}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div>
           24Karat: {formatToINR(rate?.rateOf24K || 0)}
        </div>
      ),
    },
]);


const GoldRateDisplay = () => {
    const goldRateState: GoldRateStateData = useSelector((state: StoreSelector) => state.goldRate);
    useGoldRateHook();
    const items = RateItems(GoldRateDTO.fromJSON(goldRateState));
    const {handleNavAction} = useContext(NavContext);


    return  <Dropdown menu={{ items }} placement="bottomRight">
            <div onClick={()=>handleNavAction()} className={css(styles.roundShadow)}>
                <FaRupeeSign></FaRupeeSign> Gold Rate
            </div>
      </Dropdown>
}

const styles = StyleSheet.create({
    roundShadow: {
        cursor: "pointer",
        border: "2px solid #000",
        padding: "5px",
        borderRadius: "10px 10px",
        margin: "12px",
        ":hover": {
            borderColor: primaryColor
        },
        whiteSpace: 'nowrap'
    }

})

export default GoldRateDisplay
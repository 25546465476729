import { StyleSheet, css } from "aphrodite";
import commonCss, { backgroundColor, color, margin } from "../../commonCss/commonCss";
import { softBlack, white } from "../../theme";

interface SectionHeaderProps {
    text: string;
    align?: "center" | "left" | "right";
}
export const SectionHeader = ({text, align}: SectionHeaderProps) => {
    return <div className={css(commonCss.flexRow, 
        align === "center" 
            ? commonCss.justifyCenter
            : align === "right" ? commonCss.justifyFlexEnd : null, commonCss.alignItemsCenter, margin("2rem 0 0 0"))}>
        <div className={css(commonCss.padding10, commonCss.margin10, color(white), backgroundColor(softBlack), styles.header)}>
            {text}
        </div>
    </div>

};

const styles = StyleSheet.create({
    header: {
        fontSize: "1.2rem",
        display: "inline-block",
        letterSpacing: 3,
        wordSpacing: 3,
        textTransform: "uppercase"
    }
})


import { createSlice } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { GoldRateDTO } from "../models/goldRate";


export interface GoldRateStateData {
    gold: {
        '18K': number,
        '22K': number,
        '24K': number,
        '24K995': number,
    },
    silver: number[]
}

const initialState: GoldRateStateData = {
} as GoldRateStateData;

    type SetGoldRateAction = {
        payload: GoldRateStateData
        type?: string
    }

const goldRateSlice = createSlice({
    name: "goldRate",
    initialState,
    reducers: {
        setGoldRate (state: GoldRateStateData, action: SetGoldRateAction) {
            state = action.payload
            return state;
        },
        resetGoldRate () {
            return initialState;
        }
    },
})

export const {setGoldRate, resetGoldRate} = goldRateSlice.actions;
export default goldRateSlice.reducer;
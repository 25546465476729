import { CollectionReference, DocumentData, QueryConstraint, collection, doc, getDoc, getDocs, orderBy, query, where, updateDoc, setDoc, addDoc, QueryFieldFilterConstraint, deleteDoc, QueryOrderByConstraint, QueryStartAtConstraint } from "firebase/firestore"
import { firestore } from "../config/firestore"
import { forEachChild } from "typescript";


export const Collections = {
    rates: 'rates',
    bills: 'bills',
    customers: 'customers',
    customercopy: 'customercopy',
    users: 'users',
    ratescopy:'ratescopy',
    products:'products'
}


export class Query {
    getNewDocRef() {
      throw new Error("Method not implemented.");
    }
    private collectionRef!: CollectionReference<DocumentData>;
    private constructor (collectionName: keyof typeof Collections) {
        this.collectionRef = collection(firestore, collectionName);
    }
    public static getCollection(collectionName: keyof typeof Collections) {
        return new Query(collectionName);
    }
    async getAllDocs() {
        const docs = await getDocs(this.collectionRef);
        const docsData: DocumentData[] = []
        docs.forEach(doc => docsData.push({id: doc.id, ...doc.data()}));
        return docsData
    }
    async findOne(...queryConstraint: QueryConstraint[]) {
        const q = await query(this.collectionRef, ...queryConstraint);
        const docs = await getDocs(q);
        return {id: docs.docs[0].id, ...(docs.docs[0].data())}

    }
    async findById(id: string) {
        const q = doc(this.collectionRef, id);
        const docData = await getDoc(q);
        return {...docData.data(), id: docData.id}
    }
    async updateDocById(id: string, data:Record<string, any>) {
        const q = doc(this.collectionRef, id);
        await setDoc( q, data, {merge:true});
        return id;
    }
    async addNewDoc(data: Record<string, any>) {
        return await addDoc(this.collectionRef, data);
    }

    async searchDoc(wheres: Array<QueryConstraint> ): Promise<DocumentData[]>{
        const q = await query(this.collectionRef, ...wheres)
        const snapshot = await getDocs(q);
        const docsData: DocumentData[]=[]
        if (!!snapshot) {
            snapshot.forEach(doc => {
                docsData.push({id: doc.id, ...doc.data()});
                console.log(doc.id, '=>', doc.data());
            });
            return docsData;
            
        }  
        console.log('No matching documents.');
        return [];
    }

    async removeDocById(id:string){
        const q = doc(this.collectionRef, id);
        await deleteDoc(q);
        return id;
    }
}
import { SimpleContainer, SimpleHeader, SimpleContent } from "../components/homepage/simpleContainer.component";
import { SectionHeader } from "../components/section/sectionHeaderBlock.component";
import { StyleSheet, css } from "aphrodite";
import { SectionBody } from "../components/section/sectionBody.component";
import { mobileView } from "../commonCss/commonCss";
import { RootContainer } from "../components/containers/rootContainer.component";
import jeweleryCustomization from "../assets/img/jc.jpg"
import jeweleryRepair from "../assets/img/jr.jpg"
import jeweleryPersonalization from "../assets/img/jp.jpg"
import { ImageAutoFit } from "../components/imgContainers/imageAutoFit.component";



export default function ServicesPage() {
  return <RootContainer>
    <SectionHeader text="Services" align="center"/>
    <SectionBody center={true}>
      Our Services,
      Discover a World of Jewelry Services Crafted Just for You <br/>
      At Sangini Jewellers, we offer a range of services that go beyond the ordinary. Our commitment to excellence extends to every aspect of your jewelry needs.
    </SectionBody>
      <div className={css(styles.grid)}>
        <SimpleContainer>
          <ImageAutoFit src={jeweleryCustomization} alt="Customize Jewellery Designs"/>
          <SimpleHeader>
            Custom Jewelry Design
          </SimpleHeader>
          <SimpleContent>
            Create Your Dream Piece
            Our bespoke jewelry design service brings your vision to life. From concept to creation, our artisans work closely with you to craft a unique masterpiece that reflects your style.
          </SimpleContent>
        </SimpleContainer>
        <SimpleContainer>
          <ImageAutoFit src={jeweleryRepair} alt="Jewellery Repair"/>
          <SimpleHeader>
            Jewelry Repair and Restoration:
          </SimpleHeader>
          <SimpleContent>
            Restore Brilliance, Restore Memories
            Trust our experts to breathe new life into your cherished pieces. Our repair and restoration services ensure your jewelry remains as beautiful as the day you first wore it.
          </SimpleContent>
        </SimpleContainer>
        <SimpleContainer>
          <ImageAutoFit src={jeweleryPersonalization} alt="Jewellery Personalization and Resizing" />
          <SimpleHeader>
            Resizing and Personalization
          </SimpleHeader>
          <SimpleContent>
            Perfect Fit, Personal Touch
            Whether it's resizing a ring or adding a personal engraving, our team ensures that your jewelry fits perfectly and carries a touch of your individuality.
          </SimpleContent>
        </SimpleContainer>
      </div>
    </RootContainer>
}

const styles =  StyleSheet.create({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: "10px",
    [mobileView]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    }
  }
})
